import React, { FC, useCallback, useEffect, useState } from 'react';

import { SORT_DIRECTION, TableActionHeader, TableCheckbox, TableHeader, TableRow } from '@web/components/table';
import { Typography, Button, Icon } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';
import { httpClient } from '@web/services/singletons';

interface QmrTableHeadProps {
    selectAll?: boolean;
    selectedCount?: number;
    onSelectAllChange(event: React.ChangeEvent<HTMLInputElement>): void;
    sortOrder?: string | null;
    sortDirection?: SORT_DIRECTION | null;
    onSort(sortBy: string, sortDirection: SORT_DIRECTION): void;
    onActionPress?(actionId: string): void;
    selectedItems?: any[] | null;
}

export const QmrTableHead: FC<QmrTableHeadProps> = ({
    selectAll,
    onSelectAllChange,
    sortOrder,
    sortDirection,
    onSort,
    selectedCount,
    onActionPress,
    selectedItems,
}) => {
    const { account } = useAuthState();
    const [canEscalateQmr, setCanEscalateQmr] = useState(false);

    const handleSort = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            onSort(sortBy, sortDirection);
        },
        [onSort]
    );

    const handleActionPress = useCallback(
        (actionId: string) => {
            if (!onActionPress) {
                return;
            }

            onActionPress(actionId);
        },
        [onActionPress]
    );

    useEffect(() => {
        if (selectedItems?.length === 1) {
            // We are using a separate endpoint to fetch the QMR capabilities for the selected QMR
            // because the info is not included in the QMR API list response (we removed it as of Feb 2024 for performance).
            // See https://github.com/SubaruTechShare/sts-backend/pull/1372
            let _canEscalateQmr = false;
            const config = {
                method: 'GET' as const,
                ignoreCache: true,
            };

            httpClient
                .orchestrateRequest<any>({
                    ...config,
                    url: `/qmrs/capabilities/${selectedItems[0]?.qmrId}`,
                })
                .then((qmrCapabilityResponse) => {
                    if (qmrCapabilityResponse.success) {
                        _canEscalateQmr = qmrCapabilityResponse.data?.capabilities.canEscalateQmr;
                        setCanEscalateQmr(_canEscalateQmr);
                    } else {
                        throw new Error(qmrCapabilityResponse.data?.message);
                    }
                })
                .catch((e) => {
                    setCanEscalateQmr(_canEscalateQmr);
                    alert('Error fetching QMR permissions. Some actions may not be available. Error: ' + e);
                    console.error('Error attempting to fetch QMR permissions:', e);
                });
        }
    }, [selectedItems]);
    return (
        <TableRow>
            {(account?.systemCapabilities.canExportQMRsAsCsv ||
                account?.investigationCapabilities.editAnInvestigation ||
                canEscalateQmr) && (
                <TableHeader fixed hideBorder={!selectAll}>
                    <TableCheckbox checked={selectAll} onChange={onSelectAllChange} iconName="minus" />
                </TableHeader>
            )}
            {selectAll ? (
                <>
                    <TableActionHeader colspan={10} selectedCount={selectedCount}>
                        {account?.systemCapabilities.canExportQMRsAsCsv && (
                            <Button
                                variant="ghost-blue"
                                iconLeft={<Icon name="download" color="blueOne" size={20} />}
                                onPress={() => {
                                    handleActionPress('DOWNLOAD');
                                }}
                            >
                                Export to CSV
                            </Button>
                        )}
                        {account?.investigationCapabilities.editAnInvestigation && (
                            <Button
                                variant="ghost-blue"
                                iconLeft={<Icon name="folders" color="blueOne" size={20} />}
                                onPress={() => {
                                    handleActionPress('ADD_TO_INVESTIGATION');
                                }}
                            >
                                Add to Investigation
                            </Button>
                        )}
                        {canEscalateQmr && selectedCount === 1 && (
                            <Button
                                variant="ghost-blue"
                                iconLeft={<Icon name="escalation" color="blueOne" size={20} />}
                                onPress={() => {
                                    handleActionPress('ESCALATE');
                                }}
                            >
                                Escalate
                            </Button>
                        )}
                    </TableActionHeader>
                </>
            ) : (
                <>
                    {/* Placeholder Header for Restricted status */}
                    {account?.systemCapabilities.viewQmrRestrictedOnList && <TableHeader></TableHeader>}

                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'UPDATED' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('UPDATED', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Updated
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortDirection={sortOrder === 'QMR_ID' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('QMR_ID', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            QMR ID
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortDirection={sortOrder === 'VIN' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('VIN', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            VIN
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'QMR_STATUS' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('QMR_STATUS', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            QMR Status
                        </Typography>
                    </TableHeader>
                    {account?.systemCapabilities.viewQmrRoleReviewOnList && (
                        <TableHeader
                            sortDirection={sortOrder === 'ROLE' ? sortDirection : undefined}
                            onSort={(_event, sortDirection) => {
                                handleSort('ROLE', sortDirection);
                            }}
                        >
                            <Typography variant="label" numberOfLines={1}>
                                Role Review
                            </Typography>
                        </TableHeader>
                    )}
                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'YEAR' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('YEAR', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Year
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'CARLINE' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('CARLINE', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Carline
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'FAIL_CODE' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('FAIL_CODE', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Fail Code
                        </Typography>
                    </TableHeader>
                    <TableHeader
                        sortable
                        sortDirection={sortOrder === 'RETAILER' ? sortDirection : undefined}
                        onSort={(_event, sortDirection) => {
                            handleSort('RETAILER', sortDirection);
                        }}
                        hideBorder
                        colspan={2}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Retailer
                        </Typography>
                    </TableHeader>
                </>
            )}
        </TableRow>
    );
};
