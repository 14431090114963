import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WorksheetFormElement } from '@web/administration/views/worksheets/components/worksheet-form-element';
import { prepareFormElementsWithOrder } from '@web/administration/views/worksheets/utils/worksheet-form';
import { WorksheetModal } from '@web/administration/views/worksheets/components/worksheet-modal';
import { SelectPopover } from '@web/administration/views/worksheets/components/selectPopover';
import { Button, Divider, Icon, Typography } from '@packages/ui/shared';
import { TechlineStatusCode, WorksheetAnswerStatus, WorksheetElementAnswers } from '@packages/models/api';
import AsyncPage from '@web/components/async-page';
import colors from '@packages/core/styles/colors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WorksheetToaster } from './toaster';
import { createUseStyles } from 'react-jss';
import Loader from '@web/components/loader';
import { DropResult } from 'react-beautiful-dnd';
import { useWorksheetSubmission } from './hooks/useWorksheetSubmission';
import useWorksheetSubmissionData from './hooks/useWorksheetSubmissionData';
import useUserDetailsForWorksheet from './hooks/useUserDetailsOrPermissions';
import useWorksheetValidation from './hooks/useWorksheetValidation';
interface Props {
    worksheetId?: string;
}
interface Show {
    actionMenu: boolean;
    submitModal: boolean;
    draftModal: boolean;
    toaster: boolean;
}
export const WorksheetAnswer: FC<Props> = () => {
    const classes = useNewWorksheetHeaderStyles();
    const [show, setShow] = useState<Show>({
        actionMenu: false,
        submitModal: false,
        draftModal: false,
        toaster: false,
    });
    const [formElementsAnswers, setFormElementsAnswers] = useState<Record<string, WorksheetElementAnswers>>({});
    const [toasterMessage, setToasterMessage] = useState<string>('');
    const [invalidFieldCount, setInvalidFieldCount] = useState<number>(0);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const worksheetId = searchParams.get('worksheetId') || '';
    const caseNumber = searchParams.get('casenumber') || '';
    const submissionId = searchParams.get('submissionId') || '';
    const caseStatus = searchParams.get('caseStatus') as TechlineStatusCode;

    //permission for worksheet answer submission api
    const {
        permissions: { canAddAndEditWorksheet },
    } = useUserDetailsForWorksheet();

    const { checkElementAnswer, elementsCheckingTypes } = useWorksheetValidation();
    //get worksheet data
    const { worksheetData, actions, fetching } = useWorksheetSubmissionData({
        submissionId,
        worksheetId,
        caseNumber,
    });

    //worksheet dto to FormElement
    const elements = useMemo(() => {
        return prepareFormElementsWithOrder(worksheetData?.elements || []);
    }, [worksheetData]);

    const isSubmitted = useMemo(() => {
        return worksheetData?.worksheetSubmissionStatus === WorksheetAnswerStatus.Submitted;
    }, [worksheetData?.worksheetSubmissionStatus]);

    // Whole techline's case status.
    const caseStatusTypes = useMemo(() => {
        return {
            isCloseCase: [TechlineStatusCode.CLOSED, TechlineStatusCode.CLOSED_RESOLVED].includes(caseStatus),
            isSubmittedCase: ![
                TechlineStatusCode.DRAFT,
                TechlineStatusCode.CLOSED,
                TechlineStatusCode.CLOSED_RESOLVED,
            ].includes(caseStatus),
            isDraftCase: caseStatus === TechlineStatusCode.DRAFT,
        };
    }, [caseStatus]);

    useEffect(() => {
        // Initial get the all elements ans dto and set in formElementAnswer
        let elementsSelectedOptions: { [x: string]: WorksheetElementAnswers } = actions.prepareElementAnswer(
            worksheetData?.elements || []
        );
        setFormElementsAnswers(elementsSelectedOptions);
    }, [worksheetData]);

    // Initial count of which element has not answer or required.
    useEffect(() => {
        const { conditionalOptionFormElementTypes } = elementsCheckingTypes;
        if (worksheetData) {
            const filterElementIds = worksheetData.elements.reduce((_array: string[], element) => {
                // Array of conditional element ids
                if (conditionalOptionFormElementTypes.includes(element.worksheetElementTypeId)) {
                    element.options.forEach((opt) => {
                        const trgIdList = opt.trgtWorksheetElementIdList;
                        if (trgIdList?.length) {
                            trgIdList.forEach((id) => {
                                _array.push(id);
                            });
                        }
                    });
                }
                return _array;
            }, []);

            const countRequiredFields = worksheetData.elements.reduce((count, element) => {
                if (!filterElementIds.includes(element.worksheetElementId)) {
                    const isValid = checkElementAnswer(element);
                    if (!isValid) {
                        return ++count;
                    }
                }
                return count;
            }, 0);
            setInvalidFieldCount(countRequiredFields);
        }
    }, [worksheetData]);

    // Hook for submit or as draft
    const { onSubmit, isLoading, setIsLoading } = useWorksheetSubmission({ worksheetId, caseNumber, submissionId });

    //Update Form element answer.
    const onAnswerChange = useCallback((newAnswer: WorksheetElementAnswers) => {
        setFormElementsAnswers((prev) => ({ ...prev, [newAnswer.elementId]: newAnswer }));
    }, []);

    const handleSubmit = useCallback(async () => {
        const response = await onSubmit({
            formElementAnswer: formElementsAnswers,
            status: WorksheetAnswerStatus.Submitted,
            caseStatus,
        });
        if (!response.success) {
            setToasterMessage(response.data.message);
            handleModalAndToasterShow('toaster', true);
        } else {
            setToasterMessage('Your worksheet was successfully submitted');
            handleModalAndToasterShow('toaster', true);
            setTimeout(() => {
                navigate(-1);
            }, 3000);
        }
        handleModalAndToasterShow('submitModal', false);
    }, [formElementsAnswers, navigate, onSubmit]);

    const handleSaveAsDraft = useCallback(async () => {
        const response = await onSubmit({
            formElementAnswer: formElementsAnswers,
            status: WorksheetAnswerStatus.Draft,
            caseStatus,
        });
        if (!response.success) {
            console.log(response.data.message);
            handleModalAndToasterShow('draftModal', false);
        } else {
            setTimeout(() => {
                setIsLoading((prev) => ({ ...prev, [WorksheetAnswerStatus.Draft]: false }));
                handleModalAndToasterShow('draftModal', false);
                navigate(-1);
            }, 3000);
        }
    }, [formElementsAnswers, navigate, onSubmit, setIsLoading]);

    const handleModalAndToasterShow = useCallback(
        (key: keyof Show, action: boolean) => {
            setShow((prev) => ({ ...prev, [key]: action }));
        },
        [setShow]
    );

    const handleIsValid = (valid: boolean) => {
        // When field is valid decrease one count in invalidCount or not a valid than increase one in inValidFiledCount
        setInvalidFieldCount((prev) => {
            let prevClone = prev;
            if (valid) {
                prevClone--;
            } else {
                prevClone++;
            }
            return prevClone;
        });
    };

    const handleDraftModalAction = useCallback(
        async (actions: boolean) => {
            if (actions) {
                await handleSaveAsDraft();
            }
        },
        [handleSaveAsDraft]
    );

    const handleModalAction = useCallback(
        async (action: boolean) => {
            if (action) {
                await handleSubmit();
            }
            handleModalAndToasterShow('submitModal', false);
        },
        [handleSubmit]
    );

    const getCurrentAnswerByElementId = useCallback(
        (id: string) => {
            return formElementsAnswers[id];
        },
        [formElementsAnswers]
    );
    return (
        <>
            <AsyncPage fetchData={submissionId ? actions.fetchRetrieveWorksheetSubmission : actions.fetchWorksheetData}>
                {fetching ? (
                    <Loader />
                ) : (
                    <>
                        <WorksheetToaster
                            show={show.toaster}
                            onClose={() => handleModalAndToasterShow('toaster', false)}
                            body={toasterMessage}
                        />
                        <WorksheetModal
                            show={show.submitModal}
                            onHideModal={() => handleModalAndToasterShow('submitModal', false)}
                            title="Submit worksheet"
                            bodyMessage={
                                'By submitting this worksheet, it will become visible to Techline users and can no longer be edited. Are you sure you want continue?'
                            }
                            cancelButton="Cancel"
                            confirmButton="Submit"
                            confirmButtonLoading={isLoading.SUBMITTED}
                            handleAction={handleModalAction}
                        />
                        <WorksheetModal
                            show={show.draftModal}
                            onHideModal={() => handleModalAndToasterShow('draftModal', false)}
                            title="Save worksheet"
                            bodyMessage={
                                caseStatusTypes.isSubmittedCase
                                    ? 'This worksheet will not be seen by Techline until submitted.'
                                    : 'Worksheet will be submitted on case submission.'
                            }
                            cancelButton="Cancel"
                            confirmButton="Save"
                            confirmButtonLoading={isLoading.DRAFT}
                            handleAction={handleDraftModalAction}
                        />
                        <div className={classes.view} style={{ backgroundColor: colors.grayTwo }}>
                            <div
                                style={{ backgroundColor: colors.white }}
                                className={`d-flex flex-row align-items-center justify-content-between p-2`}
                            >
                                <div className="ml-5 d-flex flex-row align-items-center">
                                    <Button variant="link" onPress={() => navigate(`/techline/${caseNumber}`)}>
                                        <Icon name="arrow-back" />
                                    </Button>
                                    <div className="ml-4 mr-4">
                                        <Typography variant="h5">{caseNumber}</Typography>
                                    </div>
                                    <Icon name="chevron-right" />
                                    <div className="ml-4">
                                        <Typography color="black" variant="h4">
                                            {worksheetData?.displayname}
                                        </Typography>
                                    </div>
                                </div>
                                {!isSubmitted && (
                                    <div className="mr-5">
                                        <SelectPopover
                                            isOpen={show.actionMenu}
                                            setShow={(isShow) => handleModalAndToasterShow('actionMenu', isShow)}
                                            options={
                                                <Button
                                                    variant="ghost-gray"
                                                    onPress={() => {
                                                        handleModalAndToasterShow('actionMenu', false);
                                                    }}
                                                    alignTitle="left"
                                                    title={'Delete'}
                                                    iconLeft={<Icon name="trash" />}
                                                />
                                            }
                                        >
                                            <div>
                                                <Button
                                                    variant="ghost-gray"
                                                    onPress={() =>
                                                        handleModalAndToasterShow('actionMenu', !show.actionMenu)
                                                    }
                                                >
                                                    <Icon
                                                        size={30}
                                                        name="more-dots-vertical"
                                                        color="blueOne"
                                                        raisedContainerStyle={{ width: 22, height: 22 }}
                                                    />
                                                </Button>
                                            </div>
                                        </SelectPopover>
                                    </div>
                                )}
                            </div>

                            <div className="w-50 p-5 mb-5 mt-5 m-auto" style={{ backgroundColor: colors.white }}>
                                {elements.map((elements) => {
                                    return (
                                        <WorksheetFormElement
                                            onAnswerChange={(newAnswer: WorksheetElementAnswers) =>
                                                onAnswerChange(newAnswer)
                                            }
                                            key={elements.formElementId}
                                            item={elements}
                                            handleDragEnd={(result: DropResult) => {}}
                                            readOnly={
                                                isSubmitted || caseStatusTypes.isCloseCase || !canAddAndEditWorksheet
                                                    ? true
                                                    : false
                                            }
                                            getCurrentAnswerByElementId={getCurrentAnswerByElementId}
                                            preview
                                            handleIsValid={handleIsValid}
                                        />
                                    );
                                })}
                                <Divider />
                                {!isSubmitted && canAddAndEditWorksheet && !caseStatusTypes.isCloseCase && (
                                    <div className="d-flex justify-content-end align-items-center mt-5 p-5">
                                        <Button
                                            variant={caseStatusTypes.isDraftCase ? 'primary' : 'ghost-gray'}
                                            onPress={() => handleModalAndToasterShow('draftModal', true)}
                                            style={{ marginRight: 5 }}
                                        >
                                            {caseStatusTypes.isDraftCase ? 'Save' : 'Save as draft'}
                                        </Button>
                                        {caseStatusTypes.isSubmittedCase && (
                                            <Button
                                                variant="primary"
                                                disabled={!!invalidFieldCount}
                                                onPress={() => handleModalAndToasterShow('submitModal', true)}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </AsyncPage>
        </>
    );
};

const useNewWorksheetHeaderStyles = createUseStyles({
    view: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    header: {
        flexShrink: 0,
        display: 'flex',
        padding: '0 30px',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.white,
        height: 80,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
});
