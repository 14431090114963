import React, { FC, useCallback, useState, useEffect } from 'react';
import { Col, Row, Modal, Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AlertBar, Button, ButtonVariant, Icon, Typography } from '@packages/ui/shared';
import { patchQmr, useQmrDispatch, useQmrReviewState, useQmrState, useQmrInfoRequests } from '@packages/contexts/qmrs';
import { useAuthState } from '@packages/contexts/auth';
import colors from '@packages/core/styles/colors';
import { Popover } from 'react-tiny-popover';
import { useAbortController } from '@packages/core/http';
import { analyticsService, qmrsService, httpClient } from '@web/services/singletons';
import { FseReviewStatusId, QmrStatusId } from '@packages/models/api';
import QmrFeedbackModal from './qmr-feedback-modal';
import OpenInfoRequestModal from './open-info-request-modal';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useInvestigationModal } from '@packages/contexts/investigations';
import { useEscalationModal } from '@packages/contexts/escalations';
import { CustomDropdownButtonType, Dropdown } from './qmr-actions-dropdown';
import { getPopoverContentLocation } from '../../utils/popover-content-location';

const useQmrActionsStyles = createUseStyles({
    container: {
        borderBottom: `1px solid ${colors.grayThree}`,
        padding: `16px 32px`,
    },
    actionsPopover: {
        minWidth: 335,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});

const QmrActions: FC<{ onRestrictQmrSuccess: () => void; onUnrestrcitQmrSuccess: () => void; hideQMRId?: boolean }> = ({
    onRestrictQmrSuccess,
    onUnrestrcitQmrSuccess,
    hideQMRId = false,
}) => {
    const classes = useQmrActionsStyles();
    const { abortSignalRef } = useAbortController();
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        updatedStatusId,
        updatedStatusValue,
        availableActions,
        primaryActionDescription,
        showPrimaryActionButton,
        primaryActionButtonVariant,
        hasManyPrimaryActions,
    } = useQmrReviewState();
    const auth = useAuthState();
    const { hasOpenInfoRequest } = useQmrInfoRequests({ qmrsService });

    const [verticalDotsMenuExpanded, setVerticalDotsMenuExpanded] = useState(false);
    const [showActionsModal, setShowActionsModal] = useState(false);
    const [showQmrFeedbackModal, setShowQmrFeedbackModal] = useState(false);
    const [updatingRestrictedStatus, setUpdatingRestrictedStatus] = useState(false);
    const [showOpenInfoReqModal, setShowOpenInfoReqModal] = useState(false);
    const [primaryActionLoader, setPrimaryActionLoader] = useState(false);

    const { showModal } = useInvestigationModal();
    const [qmrSuccessfullyAddedToInvestigation, setQmrSuccessfullyAddedToInvestigation] = useState('');

    const { showModal: showEscalationModal } = useEscalationModal();
    const [qmrSuccessfullyEscalated, setQmrSuccessfullyEscalated] = useState(false);

    const showVerticalDotsButton =
        !!qmr?.capabilities.editQmr ||
        qmr?.capabilities.giveFeedbackOnQmr ||
        qmr?.capabilities.markQmrAsRestricted ||
        auth.account?.systemCapabilities.canExportPDF;

    const transitionQmrStatus = useCallback(
        (newStatusValue = updatedStatusValue) => {
            if (!qmr || !updatedStatusId || primaryActionLoader) {
                return;
            }
            setPrimaryActionLoader(true);
            patchQmr({
                qmrId: qmr.qmrId,
                qmrDispatch,
                qmrsService,
                signal: abortSignalRef.current,
                qmrPatch: {
                    [updatedStatusId]: newStatusValue,
                },
            })
                .then((updatedQmr) => {
                    if (!updatedQmr) {
                        return;
                    }

                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_CHANGES_QMR_STATUS, {
                        tsId: updatedQmr.stsIdentifier,
                        previousQaStatus: qmr.qmrStatus.qmrStatusId,
                        newQaStatus: updatedQmr.qmrStatus.qmrStatusId,
                        previousFseStatus: qmr.fseReviewStatus.fseReviewStatusId,
                        newFseStatus: updatedQmr.fseReviewStatus.fseReviewStatusId,
                    });

                    showActionsModal && setShowActionsModal(false);

                    if (updatedQmr.capabilities.giveFeedbackOnQmr) {
                        // more info request status should show another modal first
                        if (
                            updatedStatusValue !== QmrStatusId.MoreInfoRequested &&
                            updatedStatusValue !== FseReviewStatusId.MoreInfoRequested
                        ) {
                            setShowQmrFeedbackModal(true);
                        }
                    }

                    if (updatedQmr.capabilities.openCloseMoreInfoRequest) {
                        if (
                            updatedStatusValue === QmrStatusId.MoreInfoRequested ||
                            updatedStatusValue === FseReviewStatusId.MoreInfoRequested
                        ) {
                            if (hasOpenInfoRequest) {
                                navigate(`${location.pathname}?tab=INFO`, {
                                    // search: '?tab=INFO',
                                    replace: true,
                                });
                            } else {
                                setShowOpenInfoReqModal(true);
                            }
                        }
                    }

                    if (
                        !updatedQmr.capabilities.giveFeedbackOnQmr &&
                        !updatedQmr.capabilities.openCloseMoreInfoRequest
                    ) {
                        window.location.reload();
                    }
                    setPrimaryActionLoader(false);
                })
                .catch((e) => {
                    alert(e.message);
                    setPrimaryActionLoader(false);
                });
        },
        [
            abortSignalRef,
            hasOpenInfoRequest,
            location.pathname,
            navigate,
            primaryActionLoader,
            qmr,
            qmrDispatch,
            showActionsModal,
            updatedStatusId,
            updatedStatusValue,
        ]
    );

    const toggleRestrictedStatus = useCallback(() => {
        if (!qmr || updatingRestrictedStatus) {
            return;
        }

        setUpdatingRestrictedStatus(true);

        patchQmr({
            qmrId: qmr.qmrId,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
            qmrPatch: {
                restricted: !qmr.restricted,
            },
        })
            .then((updatedQmr) => {
                if (!updatedQmr) {
                    return;
                }

                if (qmr.restricted) {
                    onUnrestrcitQmrSuccess();
                } else {
                    onRestrictQmrSuccess();
                }

                setUpdatingRestrictedStatus(false);
                setVerticalDotsMenuExpanded(false);
            })
            .catch((e) => {
                setUpdatingRestrictedStatus(false);
                setVerticalDotsMenuExpanded(false);
                alert(e.message);
            });
    }, [abortSignalRef, onRestrictQmrSuccess, onUnrestrcitQmrSuccess, qmr, qmrDispatch, updatingRestrictedStatus]);

    const renderPrimaryActionButton = () => {
        if (hasManyPrimaryActions) {
            const dropdownOptions = availableActions.map((option, index) => {
                return {
                    id: index.toString(),
                    label: option.label,
                    disabled: option.isSelected,
                };
            });
            return (
                <Dropdown
                    label={primaryActionDescription}
                    options={dropdownOptions}
                    onPress={(key) => {
                        transitionQmrStatus(availableActions[Number(key)].statusId);
                    }}
                    customDropdownButton={
                        primaryActionButtonVariant !== 'primary-dropdown'
                            ? {
                                  type: CustomDropdownButtonType.primary,
                                  variant: primaryActionButtonVariant as ButtonVariant,
                              }
                            : undefined
                    }
                    isLoading={primaryActionLoader}
                />
            );
        }
        return (
            <Button
                isLoading={primaryActionLoader}
                variant={primaryActionButtonVariant as ButtonVariant}
                onPress={() => {
                    // if (hasManyPrimaryActions) {
                    //     setShowActionsModal(true);
                    //     return;
                    // }

                    transitionQmrStatus();
                }}
            >
                {primaryActionDescription}
            </Button>
        );
    };

    useEffect(() => {
        setQmrSuccessfullyEscalated(false);
    }, [qmr]);

    const onClickDownloadButton = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const options = {
            headers: {
                'X-Subcomm-Access-Token': auth.token,
            },
        };
        let filename = '';
        fetch(`${httpClient.baseUrl}/qmrs/${qmr?.qmrId}/qmr.pdf`, options)
            .then((res) => {
                let contentDispositionHeader = res.headers.get('Content-Disposition');
                if (contentDispositionHeader) {
                    let filenameSplited = contentDispositionHeader.split('filename=');
                    if (filenameSplited && filenameSplited.length > 1) {
                        filename = filenameSplited[1].split(';')[0].replaceAll('"', '');
                    }
                }
                return res.blob();
            })
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                if (filename) {
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = file;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(file);
                } else {
                    window.location.assign(file);
                }
            });
    };

    return (
        <>
            <Modal
                centered
                show={showActionsModal}
                onHide={() => {
                    setShowActionsModal(false);
                }}
            >
                <Modal.Header closeButton placeholder={''}>
                    {t('modals:qmrActions.title', 'Choose the next step')}
                </Modal.Header>

                <Modal.Body>
                    {availableActions.map((action, index) => {
                        return (
                            <Form.Check
                                key={index}
                                className="mb-3"
                                label={action.label}
                                type="radio"
                                id={action.label}
                                checked={action.isSelected}
                                onChange={action.onSelect}
                            />
                        );
                    })}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        disabled={!updatedStatusValue}
                        title={t('modals:qmrActions.actions.continue', 'Continue')}
                        onPress={() => {
                            transitionQmrStatus();
                        }}
                    />
                </Modal.Footer>
            </Modal>

            <OpenInfoRequestModal
                show={showOpenInfoReqModal}
                onHide={() => setShowOpenInfoReqModal(false)}
                onSuccess={() => qmr != null && qmr.capabilities.giveFeedbackOnQmr && setShowQmrFeedbackModal(true)}
            />

            <QmrFeedbackModal show={showQmrFeedbackModal} onHide={() => setShowQmrFeedbackModal(false)} />

            <AlertBar
                success
                show={!!qmrSuccessfullyAddedToInvestigation}
                onClose={() => {
                    setQmrSuccessfullyAddedToInvestigation('');
                }}
            >
                <Typography variant="h5">
                    {t('alerts:qmrAddedToInvestigation.message', 'This QMR was added to the investigation')} "
                    {qmrSuccessfullyAddedToInvestigation}"
                </Typography>
            </AlertBar>

            <AlertBar
                success
                show={!!qmrSuccessfullyEscalated}
                onClose={() => {
                    setQmrSuccessfullyEscalated(false);
                }}
            >
                <Typography variant="h6">
                    {t(
                        'alerts:qmrEscalated.message',
                        'This QMR was escalated and will become a draft Subaru Technical\nReport(TR) in the TR system. This process may take up to 30 minutes \nto get into the TR system.'
                    )}
                </Typography>
            </AlertBar>

            <section className={classes.container}>
                <Row>
                    <Col>
                        <div className="d-flex align-items-center justify-content-between">
                            {!hideQMRId && (
                                <div className="d-flex flex-column">
                                    <div>
                                        <Link
                                            to={{
                                                pathname: '/qmrs',
                                                search: location.state?.listQueryParams
                                                    ? '?' + location.state.listQueryParams
                                                    : undefined,
                                            }}
                                        >
                                            <Typography color="blueOne">QMRs</Typography>
                                        </Link>

                                        <Typography style={{ paddingHorizontal: 8 }} color="textDarkSecondary">
                                            /
                                        </Typography>

                                        <Typography color="textDarkSecondary">{qmr?.displayIdentifier}</Typography>
                                    </div>

                                    <Typography variant="h5">Report</Typography>
                                </div>
                            )}
                            <div className="d-flex">
                                {!!showPrimaryActionButton && renderPrimaryActionButton()}

                                <div className="mx-4">
                                    <a
                                        href={
                                            qmr
                                                ? `mailto:?body=${encodeURIComponent(
                                                      qmr.shareUrl
                                                  )}&subject=${encodeURIComponent(qmr.displayIdentifier)}`
                                                : ''
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button variant="tertiary">
                                            <Icon name="share" />
                                        </Button>
                                    </a>
                                </div>

                                {showVerticalDotsButton && (
                                    <Popover
                                        isOpen={verticalDotsMenuExpanded}
                                        positions={['bottom', 'top']}
                                        onClickOutside={() => setVerticalDotsMenuExpanded(false)}
                                        contentLocation={({ childRect, popoverRect, position }) => {
                                            return getPopoverContentLocation(childRect, popoverRect, position, 4);
                                        }}
                                        containerClassName={classes.actionsPopover}
                                        content={
                                            <div className={classes.popoverActionsSection}>
                                                {auth.account?.systemCapabilities.canExportPDF && (
                                                    <a href="javascript:void(0);" style={{ textDecoration: 'none' }}>
                                                        <Button
                                                            alignTitle="left"
                                                            variant="ghost-gray"
                                                            onPress={(e: any) => {
                                                                setVerticalDotsMenuExpanded(false);
                                                                onClickDownloadButton(e);
                                                            }}
                                                        >
                                                            <Icon size={14} name="download" />
                                                            <Typography
                                                                style={{ paddingLeft: 12 }}
                                                                variant="labelRegular"
                                                            >
                                                                {t('buttons:download', 'Download') + ' PDF'}
                                                            </Typography>
                                                        </Button>
                                                    </a>
                                                )}
                                                {qmr?.capabilities.editQmr && (
                                                    <Button
                                                        alignTitle="left"
                                                        variant="ghost-gray"
                                                        onPress={() => {
                                                            setVerticalDotsMenuExpanded(false);
                                                            navigate(`/qmrs/${qmr?.displayIdentifier}/edit`, {
                                                                state: location.state,
                                                            });
                                                        }}
                                                    >
                                                        <Icon size={14} name="pencil" />
                                                        <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                            {t('qmrs:qmrDetail.actions.edit', 'Edit Information')}
                                                        </Typography>
                                                    </Button>
                                                )}
                                                {qmr?.capabilities.markQmrAsRestricted && (
                                                    <Button
                                                        alignTitle="left"
                                                        variant="ghost-gray"
                                                        isLoading={updatingRestrictedStatus}
                                                        onPress={toggleRestrictedStatus}
                                                    >
                                                        <Icon
                                                            size={14}
                                                            name={qmr.restricted ? 'lock-open' : 'lock-closed'}
                                                        />
                                                        <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                            {qmr.restricted
                                                                ? t(
                                                                      'qmrs:qmrDetail.actions.restrictQmr',
                                                                      'Mark QMR Unrestricted'
                                                                  )
                                                                : t(
                                                                      'qmrs:qmrDetail.actions.unrestrictQmr',
                                                                      'Mark QMR Restricted'
                                                                  )}
                                                        </Typography>
                                                    </Button>
                                                )}
                                                {qmr?.capabilities.giveFeedbackOnQmr && (
                                                    <Button
                                                        alignTitle="left"
                                                        variant="ghost-gray"
                                                        onPress={() => {
                                                            setVerticalDotsMenuExpanded(false);
                                                            setShowQmrFeedbackModal(true);
                                                        }}
                                                    >
                                                        <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                            {t('qmrs:qmrDetail.actions.giveFeedback', 'Give Feedback')}
                                                        </Typography>
                                                    </Button>
                                                )}
                                                {auth.account?.investigationCapabilities.editAnInvestigation && (
                                                    <Button
                                                        alignTitle="left"
                                                        variant="ghost-gray"
                                                        onPress={() => {
                                                            if (!qmr) {
                                                                return;
                                                            }

                                                            setVerticalDotsMenuExpanded(false);
                                                            showModal({
                                                                selectedQmrs: [qmr],
                                                                allowInvestigationReassignment: true,
                                                                onSuccess: (investigation) => {
                                                                    setQmrSuccessfullyAddedToInvestigation(
                                                                        investigation.title
                                                                    );
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <Icon
                                                            size={14}
                                                            name={qmr?.investigationId ? 'plus' : 'folders'}
                                                        />
                                                        <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                            {qmr?.investigationId
                                                                ? t(
                                                                      'qmrs:qmrDetail.actions.moveToInvestigation',
                                                                      'Move to Investigation'
                                                                  )
                                                                : t(
                                                                      'qmrs:qmrDetail.actions.addToInvestigation',
                                                                      'Add to Investigation'
                                                                  )}
                                                        </Typography>
                                                    </Button>
                                                )}
                                                {qmr?.capabilities.canEscalateQmr && (
                                                    <Button
                                                        alignTitle="left"
                                                        variant="ghost-gray"
                                                        onPress={() => {
                                                            if (!qmr) {
                                                                return;
                                                            }
                                                            setVerticalDotsMenuExpanded(false);
                                                            if (qmr.escalated) {
                                                                return window.alert('The QMR is already Escalated');
                                                            }
                                                            showEscalationModal({
                                                                selectedQmrs: [qmr],
                                                                onSuccess: () => {
                                                                    setQmrSuccessfullyEscalated(true);
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <Icon size={14} name="escalation" />
                                                        <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                            {t('qmrs:qmrDetail.actions.escalate', 'Escalate')}
                                                        </Typography>
                                                    </Button>
                                                )}
                                            </div>
                                        }
                                    >
                                        <div>
                                            <Button
                                                active={verticalDotsMenuExpanded}
                                                variant="tertiary"
                                                onPress={() => setVerticalDotsMenuExpanded(!verticalDotsMenuExpanded)}
                                            >
                                                <Icon name="more-dots-vertical" />
                                            </Button>
                                        </div>
                                    </Popover>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default QmrActions;
