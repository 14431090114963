import { FormElement, FormElementFile } from '@packages/models/api/form';
import {
    Asset,
    CreateAsset,
    CreateAssetTypes,
    DeleteAssetBody,
    WorksheetAssetType,
} from '@packages/models/api/worksheets';
import { isConditionalElementType } from '../utils';
import { maxBy } from 'lodash';

export const latestAssets = (assets: Asset[]) => {
    return maxBy(assets, (obj) => new Date(obj.createdTimestamp));
};

export const createAssetBody = (
    elementOrOptionId: string,
    formElementFile: FormElementFile,
    hasOverlay: boolean = false,
    isOptions: boolean = false,
    replace: boolean = false
): CreateAssetTypes => {
    const { file, caption, overlay } = formElementFile;
    const name = (hasOverlay ? overlay?.file?.name : file?.name)!;
    const sizeInBytes = (hasOverlay ? overlay?.file?.size : file?.size)!;
    const body: Omit<CreateAssetTypes, 'worksheetElementId' | 'worksheetElementOptionId'> = {
        assetDispositionId: 'WORKSHEET',
        assetTypeId: file?.type.includes('image') ? WorksheetAssetType.IMAGE : WorksheetAssetType.FILE,
        hasOverlay,
        name,
        caption: caption,
        ...((hasOverlay || replace) && { replacesAssetId: formElementFile.assetId }),
        ...(!replace && { sizeInBytes }),
        note: '',
    };

    return isOptions
        ? {
              ...body,
              worksheetElementOptionId: elementOrOptionId,
          }
        : {
              ...body,
              worksheetElementId: elementOrOptionId,
          };
};

export const deleteAssetBody = (
    elementOrOptionId: string,
    assetId: string,
    isOptions: boolean = false
): DeleteAssetBody => {
    return isOptions
        ? { assetId: assetId, worksheetElementOptionId: elementOrOptionId }
        : {
              assetId: assetId,
              worksheetElementId: elementOrOptionId,
          };
};

export const findAssetsAndDeleteDto = (assets: Asset[], elementOrOptionId: string, isOptions: boolean = false) => {
    const asset = latestAssets(assets);
    return deleteAssetBody(elementOrOptionId, asset?.assetId!, isOptions);
};

type Action = {
    create: boolean;
    replace: boolean;
    overlay: boolean;
    delete: boolean;
};

export const checkFileAction = (formElementFile?: FormElementFile): Action => {
    const status: Action = {
        create: false,
        replace: false,
        overlay: false,
        delete: false,
    };
    /** Check if a new file is uploaded.
     *  If 'formElementFile.file' exists, set 'create' to true.
     *  If 'formElementFile.assetId' also exists, set 'delete' to true to remove the old file. */
    if (formElementFile?.file) {
        if (formElementFile?.assetId) {
            status.replace = true;
        } else {
            status.create = true;
        }
    } else if (!formElementFile?.url && formElementFile?.assetId) {
        /** Check if the URL is undefined and 'formElementFile.assetId' exists.
         *  This indicates that the file has been removed, so set 'delete' to true.
         **/
        status.delete = true;
    }

    if (formElementFile?.overlay?.file) {
        status.overlay = true;
    }

    return status;
};

export const createAssetDto = (
    ele: FormElement,
    newAssets: { body: CreateAsset; file: File }[],
    deleteAsset: DeleteAssetBody[]
) => {
    const checkElementFileAction = checkFileAction(ele.formElementFile);
    if (checkElementFileAction.create) {
        newAssets.push({
            body: createAssetBody(ele.formElementId, ele.formElementFile!),
            file: ele.formElementFile?.file!,
        });
    }
    if (checkElementFileAction.overlay) {
        newAssets.push({
            body: createAssetBody(ele.formElementId, ele.formElementFile!, checkElementFileAction.overlay),
            file: ele.formElementFile?.overlay?.file!,
        });
    }
    if (checkElementFileAction.replace) {
        newAssets.push({
            body: createAssetBody(ele.formElementId, ele.formElementFile!, false, false, true),
            file: ele.formElementFile?.overlay?.file!,
        });
    }
    if (checkElementFileAction.delete) {
        deleteAsset.push(deleteAssetBody(ele.formElementId, ele.formElementFile?.assetId!));
    }

    // For options
    if (isConditionalElementType(ele.formElementTypeId)) {
        ele.options.forEach((opt) => {
            const checkOptionFileAction = checkFileAction(opt.optionFile);
            if (checkOptionFileAction.create) {
                newAssets.push({
                    body: createAssetBody(opt.optionId, opt.optionFile!, false, true),
                    file: opt.optionFile?.file!,
                });
            }
            if (checkOptionFileAction.overlay) {
                newAssets.push({
                    body: createAssetBody(opt.optionId, opt.optionFile!, checkOptionFileAction.overlay, true),
                    file: opt.optionFile?.overlay?.file!,
                });
            }
            if (checkOptionFileAction.delete) {
                deleteAsset.push(deleteAssetBody(opt.optionId, opt.optionFile?.assetId!, true));
            }
            if (checkOptionFileAction.replace) {
                newAssets.push({
                    body: createAssetBody(opt.optionId, opt.optionFile!, false, true, true),
                    file: opt.optionFile?.overlay?.file!,
                });
            }
        });
    }
};

export const getAssetDto = (elements: FormElement[]) => {
    const newAssets: { body: CreateAsset; file: File }[] = [];
    const deleteAssets: DeleteAssetBody[] = [];

    elements.forEach((ele) => {
        createAssetDto(ele, newAssets, deleteAssets);
        if (isConditionalElementType(ele.formElementTypeId) && ele.optionConditionsElements?.length) {
            ele.optionConditionsElements.forEach((conEle) => {
                createAssetDto(conEle, newAssets, deleteAssets);
            });
        }
    });
    return { newAssets, deleteAssets };
};
