export enum AccountStatusId {
    New = 'NEW',
    Pending = 'PENDING',
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

interface EmailAddress {
    emailAddress: string;
    emailAddressId: string;
}

interface PhoneNumber {
    phoneNumber: string;
    phoneNumberDescription: string;
    phoneNumberId: string;
}

export enum ROLE_TARGET_ID {
    REGION = 'REGION',
    DISTRICT = 'DISTRICT',
    ZONE = 'ZONE',
}

export enum ADD_ON_ROLE_ID {
    PILOT_USER = 'PILOT_USER',
}
export interface AccountLocation {
    roleId: string;
    roleTargetId: ROLE_TARGET_ID;
    roleTargetTypeId: string;
    elements: {
        title: string;
        value: string;
        shouldDisplay: boolean;
        accountLocationElementType: string;
    }[];
}

export interface Account {
    accountId: string;
    accountStatusId: AccountStatusId;
    enabled: boolean;
    soaUsername: string;
    name: string;
    firstName: string;
    lastName: string;
    timeZone: string;
    locale: string;
    emailNotificationsEnabled: boolean;
    pushNotificationsEnabled: boolean;
    createdTimestamp: string;
    createdTimestampDescription: string;
    // https://github.com/SubaruTechShare/sts-backend/blob/develop/src/main/java/io/subcomm/backend/model/security/AccountSystemCapabilities.java
    systemCapabilities: {
        createDraftQmr: boolean;
        createDraftQmrForAnyRetailer: boolean;
        feedbackNavigationEnabled: boolean;
        manageFeedbackEntities: boolean;
        manageFeedbackForms: boolean;
        manageDocumentation: boolean;
        manageHomepage: boolean;
        canSwitchAccounts: boolean;
        adminstrationNavigationEnabled: boolean;
        viewDocumentation: boolean;
        viewQmrRestrictedOnList: boolean;
        viewQmrRoleReviewOnList: boolean;
        canSearchImportedFromSubarunet: boolean;
        canSearchMyDistrict: boolean;
        canSearchMyRetailers: boolean;
        canSearchRestrictedFields: boolean;
        canShareSavedSearches: boolean;
        canShareTrReply: boolean;
        canExportQMRsAsCsv: boolean;
        canExportPDF: boolean;
        canBulkEnableUsers: boolean;
        canEditUserPermissions: boolean;
        canViewQMROutcomeRetailer: boolean;
        canViewQMROutcomeSoaNasi: boolean;
        canViewQMROutcomeSneSdc: boolean;
        canViewEscalations: boolean;
        viewInfotainmentPortal: boolean;
        viewToughbookAttachments: boolean;
        canViewFailCodeApprover: boolean;
        canViewTechline: boolean;
        canShareSearchWithUserRoles: boolean;
    };
    techlineCapabilities: {
        canAddAttachmentTechlineCase: boolean;
        canAddCaseMemberTechlineCase: boolean;
        canAddCommentsTechlineCase: boolean;
        canAddWorkSheetsTechlineCase: boolean;
        canAddWorksheetTechlineCase: boolean;
        canCloseTechlineCase: boolean;
        canCreateDLTechlineCase: boolean;
        canCreateWorkSheetsTechlineCase: boolean;
        canDeleteDLTechlineCase: boolean;
        canDeleteWorkSheetsTechlineCase: boolean;
        canEditTechlineCase: boolean;
        canEditWorkSheetsTechlineCase: boolean;
        canViewTLInfotainmentPortalLink: boolean;
        canViewTechlineCase: boolean;
        canViewWorkSheetsTechlineCase: boolean;
    };
    // https://github.com/SubaruTechShare/sts-backend/blob/feature/permissions/src/main/java/io/subcomm/backend/model/security/AccountFeedbackCapabilities.java
    feedbackCapabilities: {
        giveOrRequestFeedback: boolean;
        // giveOrRequestFeedbackToFse: boolean;
    };
    profiles: Profile[];
    emailAddresses: EmailAddress[];
    phoneNumbers: PhoneNumber[];
    locations: AccountLocation[];
    baseRoles: BaseRole[];
    baseRoleIds: Array<string>;
    baseRoleOverridden: boolean;
    addOnRoles: {
        accountRoleId: string;
        description: string;
        roleId: string;
    }[];
    locationsDescription: string;
    jobTitleDescription: string;
    baseRolesDescription: string;
    addOnRolesDescription: string;
    isRetailerUser: boolean;
    isRetailer: boolean;

    roleId: string;
    roleIdDescription: string;

    defaultEmailAddressId: string;
    defaultPhoneNumberId: string;
    defaultSavedSearchId: string;
    investigationCapabilities: {
        viewInvestigations: boolean;
        editAnInvestigation: boolean;
    };
}

export interface Profile {
    overview: string;
    details: string[];
    roleId: string;
    roleDescription: string;
}

export interface AccountJobTitle {
    jobTitleId: string;
    jobTitleDescription: string;
}

export interface AccountRole {
    roleId: string;
    roleDescription: string;
    jobTitles: AccountJobTitle[];
}

export interface BaseRole extends AccountRole {
    accountRoleId: string;
    defaultRoleDescription: string;
}

export interface AccountResponseDto {
    account: Account;
}

export interface AccountRolesResponseDto {
    rolesAndTitles: {
        roles: AccountRole[];
    };
    baseRoles: AccountRoleOption[];
    addOnRoles: AccountRoleOption[];
}

export interface AccountRoleOption {
    roleId: string;
    description: string;
}

export type PatchAccountDto = Partial<
    Pick<
        Account,
        | 'defaultEmailAddressId'
        | 'defaultPhoneNumberId'
        | 'accountStatusId'
        | 'timeZone'
        | 'pushNotificationsEnabled'
        | 'emailNotificationsEnabled'
    >
>;

export interface TimeZone {
    timeZone: string;
    description: string;
}

export interface TimeZoneResponseDto {
    timeZones: TimeZone[];
}
export interface Permission {
    description: string;
    interfaceElement: string;
    permissionId: string;
    useCase: string;
}
export interface PermissionsResponse {
    globalPermissions: Permission[];
}
