import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQmrState } from '@packages/contexts/qmrs';
import { Typography } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';

const QmrOutcome: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const { account } = useAuthState();

    if (!qmr) {
        return null;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:sections.qmrOutcome.title', 'QMR Outomce')}</Typography>

            {account?.systemCapabilities.canViewQMROutcomeRetailer && qmr.outcomeRetailerTechnicianCorporate && (
                <div className="my-4">
                    <Typography>{qmr.outcomeRetailerTechnicianCorporate}</Typography>
                </div>
            )}
            {account?.systemCapabilities.canViewQMROutcomeSoaNasi && qmr.outcomeSoaFseNasi && (
                <div className="my-4">
                    <Typography>{qmr.outcomeSoaFseNasi}</Typography>
                </div>
            )}
            {account?.systemCapabilities.canViewQMROutcomeSneSdc && qmr.outcomeSneSdcFse && (
                <div className="my-4">
                    <Typography>{qmr.outcomeSneSdcFse}</Typography>
                </div>
            )}
        </>
    );
};

export default QmrOutcome;
