import React, { useCallback, useState, useEffect } from 'react';
import { Col, Form, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { escalationsService } from '@web/services/singletons';
import { Button, Typography, TextareaHelper, TextInputHelper, SuccessFailBadge } from '@packages/ui/shared';
import { useEscalationModal } from '@packages/contexts/escalations';
import { QmrStatusId, FseReviewStatusId } from '@packages/models/api';
import Select from '@web/components/select';
import { Label } from '@packages/ui/shared/label';
import { useAuthState } from '@packages/contexts/auth';

const useStyles = createUseStyles({
    escalationModal: {},
});

export const EscalationModal = ({ onHide, ...props }: ModalProps) => {
    const { selectedQmrs, onSuccess } = useEscalationModal();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [subjectValue, setSubjectValue] = useState('');
    const [summaryValue, setSummaryValue] = useState('');
    const [priorityValue, setPriorityValue] = useState('');
    const [importanceValue, setImportanceValue] = useState('');
    const [troubleValue, setTroubleValue] = useState('');
    const [distRefNo, setDistRefNo] = useState('');
    const [FFDAvailableValue, setFFDAvailableValue] = useState('');
    const [showEscalationWarning, setShowEscalationWarning] = useState(true);
    const { account } = useAuthState();

    const handleConfirmButtonPress = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await escalationsService.createEscalation({
                body: {
                    qmrIds: selectedQmrs.map((qmr) => qmr.qmrId),
                    subject: subjectValue,
                    summary: summaryValue,
                    importance: importanceValue,
                    priority: priorityValue,
                    trouble: troubleValue,
                    distRefNo: distRefNo,
                    isFfdAvailable: FFDAvailableValue,
                },
            });

            if (response.success) {
                console.log(response.data);
                typeof onSuccess === 'function' && onSuccess(response.data.escalations);
            } else {
                throw response.data;
            }

            setIsLoading(false);
            onHide();
        } catch (error) {
            setIsLoading(false);
            window.alert(error.message);
        }
    }, [
        onHide,
        onSuccess,
        selectedQmrs,
        subjectValue,
        summaryValue,
        importanceValue,
        priorityValue,
        troubleValue,
        distRefNo,
        FFDAvailableValue,
    ]);

    useEffect(() => {
        selectedQmrs?.every((e) => {
            if (
                account?.baseRoleIds.includes('FSE_USER') ||
                account?.baseRoleIds.includes('DSQM') ||
                account?.baseRoleIds.includes('DISTRICT_SQM')
            ) {
                return e.fseReviewStatus.fseReviewStatusId === FseReviewStatusId.NoActionNeeded;
            } else {
                return (
                    e.qmrStatus.qmrStatusId === QmrStatusId.NoActionNeeded ||
                    e.qmrStatus.qmrStatusId === QmrStatusId.FurtherStudy
                );
            }
        })
            ? setShowEscalationWarning(false)
            : setShowEscalationWarning(true);
    }, [selectedQmrs]);

    return (
        <Modal centered dialogClassName={classes.escalationModal} onHide={onHide} {...props}>
            <Modal.Header placeholder={''}>
                <Modal.Title>
                    {!showEscalationWarning
                        ? t('modals:escalations.title', 'Escalation')
                        : t('modals:escalations.warning.title', 'Escalation Error')}
                </Modal.Title>
            </Modal.Header>
            {!showEscalationWarning ? (
                <Modal.Body>
                    <div className="mb-3">
                        <Typography>
                            {selectedQmrs.length !== 1
                                ? t(
                                      'modals:escalations.bodyMultiple',
                                      'You are about to escalate these QMRs. They will become draft Subaru Technical Reports (TR).'
                                  )
                                : t(
                                      'modals:escalations.bodySingular',
                                      'You are about to escalate this QMR. This will become a draft Subaru Technical Report (TR).'
                                  )}
                        </Typography>
                    </div>
                    <div className="mb-3">
                        <TextareaHelper
                            required
                            label="Subject"
                            containerStyle={{ height: 80 }}
                            value={subjectValue}
                            onChangeText={setSubjectValue}
                        />
                    </div>
                    <div className="mb-3">
                        <TextareaHelper
                            required
                            label="Summary"
                            containerStyle={{ height: 80 }}
                            value={summaryValue}
                            onChangeText={setSummaryValue}
                        />
                    </div>
                    <div className="mb-3">
                        <Typography>
                            {t(
                                'modals:escalations.footerNote',
                                'NOTE: Subject and summary may be modified by SBR after escalation'
                            )}
                        </Typography>
                    </div>
                    <Form.Row className="mb-5">
                        <Col>
                            <Label optional={false} required={true}>
                                <Typography variant="label">
                                    {t('qmr:quickFilters.modelYear.from', 'Importance')}
                                </Typography>
                            </Label>
                            <Select
                                required={true}
                                value={importanceValue}
                                options={[
                                    { title: 'Select importance', value: '', disabled: true },
                                    { title: 'S: Stop Sale', value: '1' },
                                    { title: 'A Must Item', value: '2' },
                                    { title: 'B Want Item', value: '3' },
                                    { title: 'C Comm or Sugg', value: '4' },
                                ]}
                                onChange={(event) => {
                                    setImportanceValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Label optional={false} required={true}>
                                <Typography variant="label">
                                    {t('modals:escalations.footerNote', 'Priority')}
                                </Typography>
                            </Label>
                            <Select
                                required={true}
                                value={priorityValue}
                                options={[
                                    { title: 'Select priority', value: '', disabled: true },
                                    { title: 'S', value: 'S' },
                                    { title: 'A', value: 'A' },
                                    { title: 'D', value: 'D' },
                                    { title: 'E', value: 'E' },
                                ]}
                                onChange={(event) => {
                                    setPriorityValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                        <Col>
                            <Label optional={false} required={true}>
                                <Typography variant="label">
                                    {t('qmr:quickFilters.modelYear.from', 'Trouble')}
                                </Typography>
                            </Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mb-4">
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'EG'}
                                type="radio"
                                id="EG"
                                value="0001"
                                name="trouble_EG"
                                checked={troubleValue === '0001'}
                                onChange={(event) => {
                                    setTroubleValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'TM'}
                                type="radio"
                                id="TM"
                                value="0002"
                                name="trouble_TM"
                                checked={troubleValue === '0002'}
                                onChange={(event) => {
                                    setTroubleValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'Body'}
                                type="radio"
                                id="Body"
                                value="0003"
                                name="trouble_Body"
                                checked={troubleValue === '0003'}
                                onChange={(event) => {
                                    setTroubleValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'Paint'}
                                type="radio"
                                id="Paint"
                                value="0004"
                                name="trouble_Paint"
                                checked={troubleValue === '0004'}
                                onChange={(event) => {
                                    setTroubleValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                        <Col>
                            <Typography variant="label">
                                {t('qmr:quickFilters.modelYear.from', 'Dist Ref No')}
                            </Typography>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                        <Col>
                            <TextInputHelper
                                value={distRefNo}
                                onChangeText={setDistRefNo}
                                placeholder="Enter a label (32 character limit)"
                                maxLength={32}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row className="mb-2">
                        <Col>
                            <Label optional={false} required={true}>
                                <Typography variant="label">
                                    {t('qmr:quickFilters.modelYear.from', 'Is FFD available?')}
                                </Typography>
                            </Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mb-4">
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'Available'}
                                type="radio"
                                id="available"
                                value="1"
                                name="available"
                                checked={FFDAvailableValue === '1'}
                                onChange={(event) => {
                                    setFFDAvailableValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={'Not available'}
                                type="radio"
                                id="not-available"
                                value="2"
                                name="not-available"
                                checked={FFDAvailableValue === '2'}
                                onChange={(event) => {
                                    setFFDAvailableValue(event.currentTarget.value);
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <SuccessFailBadge success={false} style={{ height: 70 }}>
                        {t(
                            'modals:escalations.warningNote',
                            selectedQmrs.length > 1
                                ? 'One or more of the QMRs cannot be escalated because it is not in a completed status. Please check the status of the selected QMRs.'
                                : 'This QMR cannot be escalated because it is not in a completed status. Please check the status of the QMR.'
                        )}
                    </SuccessFailBadge>
                </Modal.Body>
            )}
            <Modal.Footer>
                {!showEscalationWarning ? (
                    <>
                        <Button
                            style={{
                                marginRight: 7,
                            }}
                            variant="ghost-blue"
                            onPress={onHide}
                            title={t('buttons:cancel', 'Cancel')}
                        />
                        <Button
                            disabled={
                                !summaryValue ||
                                !subjectValue ||
                                !priorityValue ||
                                !importanceValue ||
                                !troubleValue ||
                                !FFDAvailableValue
                            }
                            isLoading={isLoading}
                            variant="primary"
                            onPress={handleConfirmButtonPress}
                            title={t('buttons:confirm', 'Escalate')}
                        />
                    </>
                ) : (
                    <Button
                        style={{
                            marginRight: 7,
                        }}
                        variant="ghost-blue"
                        onPress={onHide}
                        title={t('buttons:okay', 'Okay')}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};
