import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    ApiResponse,
    CaseType,
    DeleteDraftWorksheetSubmissionResponse,
    TechlineStatusCode,
    WorksheetAnswerStatus,
    WorksheetSubmissions,
} from '@packages/models/api';
import { BadgeVariants, Button, Divider, Icon, Label, TextBadge, Typography } from '@packages/ui/shared';
import Select from '@web/components/select';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectPopover } from '@web/administration/views/worksheets/components/selectPopover';
import { WorksheetModal } from '@web/administration/views/worksheets/components/worksheet-modal';
import { WorksheetToaster } from './toaster';
import { DateTime } from 'luxon';
import useUserDetailsForWorksheet from './hooks/useUserDetailsOrPermissions';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { analyticsService } from '@web/services/singletons';
export const answerStatus: { [x in WorksheetAnswerStatus]: { label: string; color: BadgeVariants } } = {
    [WorksheetAnswerStatus.Submitted]: { label: 'Submitted', color: 'green' },
    [WorksheetAnswerStatus.Draft]: { label: 'Draft', color: 'default' },
};
export const DeleteMessage = {
    deleteSubmitted:
        'Are you sure you want to delete this worksheet? Please note that to submit a case,a case type and its corresponding worksheet must be filled with required information.',
    deleteRemaining: 'Are you sure you want to delete this worksheet?',
};

interface Props {
    selectedCaseType: CaseType;
    worksheetList: { worksheetId: string; displayName: string }[];
    worksheetSubmittedOrDraft?: WorksheetSubmissions[];
    deleteWorksheetDraft: (submissionId: string) => Promise<ApiResponse<DeleteDraftWorksheetSubmissionResponse>>;
    caseStatus?: TechlineStatusCode;
    isCloseCase: boolean;
}

export const WorksheetAdd: FC<Props> = ({
    selectedCaseType,
    worksheetList,
    worksheetSubmittedOrDraft,
    deleteWorksheetDraft,
    caseStatus,
    isCloseCase,
}) => {
    const { caseNumber } = useParams<{ worksheetId: string; caseNumber: string }>();
    const navigate = useNavigate();
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDeleteModal, setDeleteModal] = useState<{ show: boolean; submissionId: string }>({
        show: false,
        submissionId: '',
    });
    const [worksheetListClone, setWorksheetListClone] = useState<
        {
            worksheetId: string;
            displayName: string;
        }[]
    >([]);
    const [showActionMenu, setShowActionMenu] = useState<number>(-1);
    const [showToast, setShowToast] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
    const [selectedWorksheetId, setSelectedWorksheetId] = useState<string>('');
    const [isDeletedLoading, setIsDeletedLoading] = useState(false);
    const {
        permissions: { canAddAndEditWorksheet },
    } = useUserDetailsForWorksheet();

    useEffect(() => {
        if (worksheetList?.length) {
            // Array of worksheet ids which is already in draft.
            const draftWorksheetId = worksheetSubmittedOrDraft?.reduce((_array: string[], worksheet) => {
                if (worksheet.status === WorksheetAnswerStatus.Draft) {
                    _array.push(worksheet.worksheetId);
                }
                return _array;
            }, []);

            // Filter if worksheet already in draft.
            const filterWorksheetList = draftWorksheetId?.length
                ? worksheetList.filter((worksheet) => !draftWorksheetId.includes(worksheet.worksheetId))
                : worksheetList;

            setWorksheetListClone(filterWorksheetList);
            setSelectedWorksheetId(filterWorksheetList[0]?.worksheetId);
        }
    }, [worksheetList, worksheetSubmittedOrDraft]);

    const handleAddModalAction = useCallback(
        (action: boolean) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_ADDS_WORKSHEET);
            if (action) {
                navigate(
                    `/techline/worksheet-answer?worksheetId=${selectedWorksheetId}&casenumber=${caseNumber}&caseStatus=${caseStatus}`
                );
            } else {
                setShowAddModal(false);
            }
        },
        [caseNumber, caseStatus, navigate, selectedWorksheetId]
    );

    const handleDeleteWorksheetDraft = useCallback(async () => {
        const response = await deleteWorksheetDraft(showDeleteModal.submissionId);
        if (response.data.message) {
            setShowToast({ show: true, message: response.data.message });
        }
        setIsDeletedLoading(false);
    }, [deleteWorksheetDraft, showDeleteModal.submissionId]);

    const handleDeleteModalAction = useCallback(
        async (action: boolean) => {
            if (action) {
                setIsDeletedLoading(true);
                await handleDeleteWorksheetDraft();
            }
            setDeleteModal({ show: false, submissionId: '' });
        },
        [handleDeleteWorksheetDraft]
    );
    return (
        <>
            <WorksheetToaster
                show={showToast.show}
                onClose={() => setShowToast({ show: false, message: '' })}
                body={showToast.message}
                styles={{ backgroundColor: 'black' }}
                textColor="white"
            />

            <section className="mt-10">
                <WorksheetModal
                    show={showAddModal}
                    onHideModal={() => setShowAddModal(false)}
                    title="Add worksheet"
                    bodyMessage={
                        <>
                            <Label required>Worksheet</Label>
                            <Select
                                value={selectedWorksheetId}
                                options={worksheetListClone?.map((worksheet, index) => ({
                                    title: worksheet.displayName,
                                    value: worksheet.worksheetId,
                                }))}
                                onChange={(event) => {
                                    const value = event.currentTarget.value;
                                    setSelectedWorksheetId(value);
                                }}
                            />
                        </>
                    }
                    cancelButton="Cancel"
                    confirmButton="Add"
                    handleAction={handleAddModalAction}
                />
                <WorksheetModal
                    show={showDeleteModal.show}
                    onHideModal={() => setDeleteModal((prev) => ({ show: false, submissionId: '' }))}
                    title="Delete worksheet"
                    bodyMessage={DeleteMessage.deleteSubmitted}
                    cancelButton="Cancel"
                    confirmButton="Delete"
                    confirmButtonColor="danger"
                    confirmButtonLoading={isDeletedLoading}
                    handleAction={handleDeleteModalAction}
                />
                <>
                    <div className="d-flex justify-content-between">
                        <Label required>{selectedCaseType.caseType}</Label>
                        {!isCloseCase && canAddAndEditWorksheet && (
                            <Button
                                variant="ghost-blue"
                                disabled={!Boolean(worksheetListClone?.length)}
                                onPress={() => {
                                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_ADDS_WORKSHEET);
                                    setShowAddModal(true);
                                }}
                            >
                                Add Worksheet
                            </Button>
                        )}
                    </div>

                    {worksheetSubmittedOrDraft && worksheetSubmittedOrDraft.length > 0 ? (
                        worksheetSubmittedOrDraft?.map((worksheet, index) => {
                            const isWorksheetSubmitted = worksheet.status === WorksheetAnswerStatus.Submitted;
                            return (
                                <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center
                    p-4 border mt-3 mb-10"
                                    style={{ borderRadius: 3, cursor: 'pointer' }}
                                    onClick={() => {
                                        analyticsService.logEvent(ANALYTICS_EVENTS.USER_VIEWS_WORKSHEET);
                                        navigate(
                                            `/techline/worksheet-answer?worksheetId=${worksheet.worksheetId}&casenumber=${caseNumber}&submissionId=${worksheet.submissionId}&caseStatus=${caseStatus}`
                                        );
                                    }}
                                >
                                    <div>
                                        <Label>{worksheet.worksheetTitle}</Label>
                                        <Typography>
                                            {DateTime.fromISO(worksheet.lastUpdatedTimestamp).toFormat(
                                                'LLLL dd, yyyy, h:mm:ss a'
                                            )}
                                            <span className="border-left ml-1"> by {worksheet.userDisplayName}</span>
                                        </Typography>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <TextBadge
                                            variant={answerStatus[worksheet.status].color}
                                            style={{ paddingHorizontal: 10, marginRight: 10 }}
                                        >
                                            {answerStatus[worksheet.status].label}
                                        </TextBadge>
                                        {!isWorksheetSubmitted && !isCloseCase && (
                                            <SelectPopover
                                                isOpen={showActionMenu === index}
                                                setShow={(isShow) => {
                                                    if (!isShow) setShowActionMenu(-1);
                                                }}
                                                options={
                                                    <>
                                                        {canAddAndEditWorksheet && (
                                                            <Button
                                                                variant="ghost-gray"
                                                                onPress={() => {
                                                                    analyticsService.logEvent(
                                                                        ANALYTICS_EVENTS.USER_VIEWS_WORKSHEET
                                                                    );
                                                                    navigate(
                                                                        `/techline/worksheet-answer?worksheetId=${worksheet.worksheetId}&casenumber=${caseNumber}&submissionId=${worksheet.submissionId}&caseStatus=${caseStatus}`
                                                                    );
                                                                }}
                                                                alignTitle="left"
                                                                title={'Edit'}
                                                                iconLeft={<Icon name="pencil" />}
                                                            />
                                                        )}
                                                        <Button
                                                            variant="ghost-gray"
                                                            onPress={() => {
                                                                analyticsService.logEvent(
                                                                    ANALYTICS_EVENTS.USER_DELETES_WORKSHEET_DRAFT
                                                                );
                                                                setDeleteModal({
                                                                    show: true,
                                                                    submissionId: worksheet.submissionId,
                                                                });
                                                                setShowActionMenu(-1);
                                                            }}
                                                            alignTitle="left"
                                                            title={'Delete'}
                                                            iconLeft={<Icon name="trash" />}
                                                        />
                                                    </>
                                                }
                                            >
                                                <div>
                                                    <Button
                                                        variant="ghost-gray"
                                                        size="small"
                                                        onPress={() =>
                                                            setShowActionMenu(showActionMenu !== -1 ? -1 : index)
                                                        }
                                                    >
                                                        <Icon
                                                            size={26}
                                                            name="more-dots-vertical"
                                                            color="blueOne"
                                                            raisedContainerStyle={{ width: 22, height: 30 }}
                                                        />
                                                    </Button>
                                                </div>
                                            </SelectPopover>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="mb-8 mt-8">
                            <Typography color="redOne">
                                At least one worksheet is required for every case type.
                            </Typography>
                        </div>
                    )}
                </>

                <Divider />
            </section>
        </>
    );
};
