import React, { FC, useRef, useState } from 'react';
import WebWrapper from './web-wrapper';
import MobileWrapper from './mobile-wrraper';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import colors from '@packages/core/styles/colors';
import { Typography } from '../typography';

interface Props {
    children: any;
    content: string;
    isValid: boolean;
    contentZIndex?: number | string;
}

const WrapperAndContent: FC<Props> = ({ children, content, isValid, contentZIndex }) => {
    const [show, setShow] = useState(false);
    const isWeb = Platform.OS === 'web';
    const isMobile = ['android', 'ios'].includes(Platform.OS);
    const refParagraph = useRef<HTMLDivElement>(null);
    const handleMouseEnter = () => {
        setShow(true);
    };

    const handleMouseLeave = () => {
        setShow(false);
    };
    return (
        <>
            {isWeb && (
                <>
                    <p
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                            color: colors.blueOne,
                            fontSize: 15,
                            cursor: 'pointer',
                            zIndex: contentZIndex ? contentZIndex : 9999,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={refParagraph}
                    >
                        {content}
                    </p>
                    {isValid && (
                        <>
                            <WebWrapper
                                show={show}
                                parentRef={refParagraph}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                            >
                                {children}
                            </WebWrapper>
                        </>
                    )}
                </>
            )}
            {isMobile && (
                <>
                    <TouchableWithoutFeedback
                        onLongPress={() => {
                            setShow(true);
                        }}
                    >
                        <Typography color="blueOne">{content}</Typography>
                    </TouchableWithoutFeedback>
                    {isValid && (
                        <MobileWrapper show={show} onHide={() => setShow(false)}>
                            {children}
                        </MobileWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default WrapperAndContent;
