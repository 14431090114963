import { ConditionalElement, FormElementAction, FormElementFile, FormElementOption } from '@packages/models/api/form';
import {
    WorksheetFormElement,
    DraggableWorksheetFormComponent,
    draggableFormElements,
    WorksheetAssetImageType,
} from '@packages/models/api';
import { addQuestionNumber } from '../components/utils';
import { isEqual, omit, orderBy } from 'lodash';
import { isConditionalElementType } from '../utils';
import { Asset, WorksheetAssetType, WorksheetFormElementOptions } from '@packages/models/api/worksheets';
import { latestAssets } from './worksheet-assets';
import uuid from 'uuid';

export const findLatest = (assets?: Asset[]): FormElementFile => {
    if (!assets?.length) {
        return {
            url: undefined,
            name: undefined,
            caption: undefined,
            assetId: undefined,
        };
    }
    const asset = latestAssets(assets);
    const media =
        asset?.assetTypeId === WorksheetAssetType.FILE
            ? asset.media?.[0]
            : asset!.media?.find((m) => m.assetImageTypeId === WorksheetAssetImageType.ORIGINAL);

    return {
        url: media?.url,
        name: asset?.name,
        caption: asset?.caption,
        assetId: asset?.assetId,
        status: asset?.assetProcessingStateId,
        overlay: {},
    };
};

export const manageElement = (element: WorksheetFormElement, changeElementsIds: { [x: string]: string }) => {
    const elementId = uuid();
    changeElementsIds[element.worksheetElementId] = elementId;
    element.worksheetElementId = elementId;
    element.action = FormElementAction.Insert;
    element.options.forEach((option) => {
        option.optionId = uuid();
    });
};

export const checkNewAssetIsEqual = (asset?: FormElementFile) => {
    return asset ? Boolean(asset.file) : true;
};

export const checkOptionsIsEqual = (
    newOptions: WorksheetFormElementOptions[],
    oldOptions: WorksheetFormElementOptions[]
) => {
    for (const oldOption of oldOptions) {
        const elementOption = newOptions.find((opt) => opt.optionId === oldOption.optionId);

        if (elementOption) {
            let equalOption = isEqual(omit(oldOption, ['assets']), omit(elementOption, ['assets', 'optionFile']));

            // Check if there's a new asset file, indicating a change
            if (equalOption && elementOption.optionFile) {
                equalOption = !checkNewAssetIsEqual(elementOption.optionFile);
            }

            // If any option is not equal, return false early
            if (!equalOption) {
                return false;
            }
        } else {
            // If no matching option is found, return false
            return false;
        }
    }

    // If all options match, return true
    return true;
};

export const checkElementIsEqual = (newElement: WorksheetFormElement, oldElement: WorksheetFormElement) => {
    const equal = isEqual(
        omit(newElement, ['options', 'formElementFile', 'assets']),
        omit(oldElement, ['options', 'assets'])
    );
    // Compare the main properties excluding 'options', 'formElementFile', and 'assets'
    if (!equal) {
        return false;
    }

    // Check formElementFile
    return !checkNewAssetIsEqual(newElement.formElementFile);
};

export const checkAllAttributeElementEqual = (newElement: WorksheetFormElement, oldElement: WorksheetFormElement) => {
    // Step 1: Compare the main properties excluding 'options', 'formElementFile', and 'assets'
    if (!checkElementIsEqual(newElement, oldElement)) {
        return false;
    }

    // Step 2: Check formElementFile (assuming checkAsset returns a boolean)
    if (checkNewAssetIsEqual(newElement.formElementFile)) {
        return false;
    }

    // Step 3: Check options
    if (!checkOptionsIsEqual(newElement.options, oldElement.options)) {
        // Early return if lengths differ
        if (newElement.options.length !== oldElement.options.length) {
            return false;
        }
        return false;
    }

    // If all checks pass
    return true;
};

export const getConditionalParentElementIndex = (
    elementsClone: WorksheetFormElement[],
    changeElementIds: { [x: string]: string }
) => {
    return elementsClone
        .map((element, index) => {
            if (isConditionalElementType(element.worksheetElementTypeId)) {
                const shouldChange = element.options.some((opt) =>
                    opt.trgtWorksheetElementIdList.some((trgId) => changeElementIds[trgId])
                );
                if (shouldChange) {
                    return index;
                }
            }
            return -1;
        })
        .filter((idx) => idx !== -1);
};

export const removeUnnecessaryPropertiesFromElement = (
    elements: WorksheetFormElement[],
    isAssetsDelete: boolean = true
) => {
    /*
     * Remove the Properties option's optionFile or the element's formElementFile
     * as these properties are unnecessary for updating or creating a worksheet
     * for the endpoint.
     */
    return elements.map((ele) => {
        delete ele.formElementFile;
        if (isAssetsDelete) {
            delete ele.assets;
        }
        if (isConditionalElementType(ele.worksheetElementTypeId)) {
            ele.options.forEach((opt) => {
                delete opt.optionFile;
                if (isAssetsDelete) {
                    delete opt.assets;
                }
            });
        }
        return ele;
    });
};

export const prepareElement = (element: WorksheetFormElement) => {
    return {
        ...draggableFormElements?.[element.worksheetElementTypeId],
        formElementId: element.worksheetElementId,
        formElementTypeId: element.worksheetElementTypeId,
        formElementLabel: element.worksheetElementLabel,
        formElementTooltip: element.worksheetElementTooltip,
        formElementDescription: element.worksheetElementDescription,
        formElementHasRule: element.worksheetElementHasRule,
        formElementOrder: element.worksheetElementOrder,
        required: element.worksheetElementRequired,
        formElementFile: element.formElementFile ? element.formElementFile : findLatest(element.assets),
        ...(element.selectedOptions && { selectedOptions: element.selectedOptions }),
        options: element.options
            .map((option) => {
                const _option = {
                    ...option,
                    trgtElementIdList: option?.trgtWorksheetElementIdList,
                };
                if (option.optionFile) {
                    _option.optionFile = option.optionFile;
                } else {
                    _option.optionFile = findLatest(option.assets);
                }
                omit(_option, 'trgtWorksheetElementIdList');
                return _option;
            })
            .sort((a, b) => a.optionDisplayOrder - b.optionDisplayOrder),
        action: element.action,
        assets: element.assets,
        answerAssets: element.answerAssets,
    };
};

export const parseConditionalElements = (
    elementList: WorksheetFormElement[],
    option: FormElementOption,
    deleteComponent: string[]
) => {
    const optionConditionsElements: ConditionalElement[] = [];
    option.trgtElementIdList?.forEach((element: string) => {
        const conditionalIndex = elementList.findIndex(
            (item: WorksheetFormElement) => element === item.worksheetElementId
        );
        if (conditionalIndex > -1) {
            const _conditional = {
                ...prepareElement(elementList[conditionalIndex]),
                targetOptionId: option.optionId,
            };
            _conditional.options.forEach((option: FormElementOption) => {
                if (option.trgtElementIdList && option.trgtElementIdList.length > 0) {
                    _conditional.optionConditionsElements = parseConditionalElements(
                        elementList,
                        option,
                        deleteComponent
                    );
                }
            });
            optionConditionsElements.push(_conditional);
            deleteComponent.push(elementList[conditionalIndex].worksheetElementId);
        }
    });

    return optionConditionsElements;
};

export const prepareFormElements = (elementList: WorksheetFormElement[]): DraggableWorksheetFormComponent[] => {
    const deleteComponentIds: Array<string> = [];
    return elementList
        .map((element) => {
            let optionConditionsElements: ConditionalElement[] = [];
            const _element = prepareElement(element);
            if (isConditionalElementType(_element.formElementTypeId)) {
                _element.options.forEach((option: FormElementOption) => {
                    optionConditionsElements = optionConditionsElements.concat(
                        parseConditionalElements(elementList, option, deleteComponentIds)
                    );
                });
            }
            _element.optionConditionsElements = optionConditionsElements;
            return _element;
        })
        .filter((e) => !deleteComponentIds.includes(e.formElementId));
};

export const prepareFormElementsWithOrder = (
    elementList: WorksheetFormElement[]
): DraggableWorksheetFormComponent[] => {
    let formElements = prepareFormElements(elementList);
    formElements = formElements.map((ele) => {
        if (ele.optionConditionsElements?.length) {
            ele.optionConditionsElements = orderBy(ele.optionConditionsElements, ['formElementOrder'], ['asc']);
        }
        return ele;
    });
    formElements = addQuestionNumber(formElements);

    return formElements;
};
