import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useQuery from '@web/core/hooks/use-query';
import { useSummariesState } from '@web/core/hooks/use-summaries';
import { feedbackService, investigationService, qmrsService, httpClient } from '@web/services/singletons';
import NavigationItem from '@web/components/navigation-item';
import NavigationSubItem from '@web/components/navigation-sub-item';
import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { FeedbackGroup, FeedbackGroupType, InvestigationModel } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import { useAuthState } from '@packages/contexts/auth';
import { NavigationItemHoverTitle } from './navigation-item-hover-title';
import { useAdvancedSearchContext } from '@packages/contexts/qmrs';
import { Collapse } from 'react-bootstrap';
type ExpandableNavItems = 'qmr' | 'feedback' | 'investigations' | 'administration' | 'techline';
const useNavigationStyles = createUseStyles({
    navigationOuter: ({ open }: { open: boolean }) => ({
        display: 'flex',
        position: 'relative',
        width: open ? 248 : 48,
        transition: '0.2s width',
    }),
    navigation: {
        display: 'flex',
        minWidth: '100%',
        padding: '16px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexDirection: 'column',
        backgroundColor: colors.grayTwo,
        borderRight: `1px solid ${colors.grayThree}`,
    },
    collapseButtonOuter: {
        position: 'absolute',
        bottom: 32,
        right: -12,
    },
    collapseButton: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blueFour,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
    },
    navigationSubItemCollapseButton: {
        border: 0,
        borderRadius: 0,
        display: 'block',
        padding: '10px 0',
        appearance: 'none',
        backgroundColor: 'transparent',
    },
});
interface NavigationProps {
    open: boolean;
    onOpenTogglePress(open: boolean): void;
}
const Navigation: FC<NavigationProps> = ({ open, onOpenTogglePress }) => {
    const { t } = useTranslation();
    const landingRouteMatch = useMatch('/');
    const authRouteMatch = useMatch('/auth');
    const onboardingRouteMatch = useMatch('/onboard');
    const adminRouteMatch = useMatch('/administration');
    const adminWildcardRouteMatch = useMatch('/administration/*');
    const qmrRouteMatch = useMatch('/qmrs');
    const qmrWildcardRouteMatch = useMatch('/qmrs/*');
    const qmrDetailRouteMatch = useMatch('/qmrs/:id');
    const qmrEditRouteMatch = useMatch('/qmrs/:id/edit');
    const qmrsSlashDashboard = useMatch('/qmrs/dashboard/*');
    const advancedSearchResultsRouteMatch = useMatch('/qmrs/advanced-search/:searchId');
    const savedSearchRouteMatch = useMatch('/qmrs/advanced-search/saved');
    const advancedSearchRouteMatch = useMatch('/qmrs/advanced-search/search');
    const techlineDashboardRouteMatch = useMatch('/techline/dashboard');
    const techlineAllCasesRouteMatch = useMatch('/techline/all-cases');
    const techlineCreateWorksheetRouteMatch = useMatch('/techline/worksheet-answer');
    const worksheetPreviewRouteMatch = useMatch('/administration/worksheet/preview/:worksheetId');
    const techlineNewOpenCasesRouteMatch = useMatch('/techline/:caseNumber');
    const isActualNewOpenCasesRouteMatch =
        techlineNewOpenCasesRouteMatch &&
        techlineNewOpenCasesRouteMatch?.params?.caseNumber !== 'dashboard' &&
        techlineNewOpenCasesRouteMatch?.params?.caseNumber !== 'all-cases';
    const techlineRecentSearchesRouteMatch = useMatch('/techline/recent-searches');
    const techlineOpenRouteMatch = useMatch('/techline/open');
    const techlineRouteMatch =
        !!techlineDashboardRouteMatch ||
        !!techlineAllCasesRouteMatch ||
        !!isActualNewOpenCasesRouteMatch ||
        !!techlineOpenRouteMatch ||
        !!techlineRecentSearchesRouteMatch;
    const investigationsRouteMatch = useMatch('/investigations');
    const investigationsWildcardRouteMatch = useMatch('/investigations/*');
    const escalationsRouteMatch = useMatch('/escalations');
    const escalationsWildcardRouteMatch = useMatch('/escalations/*');
    const investigationsDetailRouteMatch = useMatch('/investigations/:id');
    const feedbackRouteMatch = useMatch('/feedback');
    const feedbackWildcardRouteMatch = useMatch('/feedback/*');
    const manageUsersRouteMatch = useMatch('/administration/users');
    const manageEntitiesRouteMatch = useMatch('/administration/entities');
    const manageRetailersRouteMatch = useMatch('/administration/retailers');
    const manageWorkSheetsRouteMatch = useMatch('/administration/worksheets');
    const manageFeedbackRouteMatch = useMatch('/administration/feedback');
    const toughbookAttachmentsRouteMatch = useMatch('/administration/toughbook-attachments');
    const manageDocumentationRouteMatch = useMatch('/user-articles/*');
    const manageContentSettings = useMatch('/administration/content-settings');
    const qmrsDashboardContentSettings = useMatch('/administration/content-settings/qmrsdashboard');
    const techlineDashboardContentSettings = useMatch('/administration/content-settings/techline');
    const failCodeTeamRouteMatch = useMatch('/administration/fail-code-teams');
    const query = useQuery();
    const queryGroupType = query.get('groupType') || FeedbackGroupType.All;
    const queryEntityId = query.get('entityId');
    const { account } = useAuthState();
    const { fetchQmrSummaries } = useSummariesState();
    const { recentSavedSearches, setRecentSavedSearches } = useAdvancedSearchContext();
    const [savedSearchesOpen, setSavedSearchesOpen] = useState(true);
    const [permissions, setPermissions] = useState<any>([]);
    const [recentInvestigations, setRecentInvestigations] = useState<InvestigationModel[]>([]);
    const hideNavigation = !!qmrsSlashDashboard
        ? !qmrsSlashDashboard
        : authRouteMatch ||
          onboardingRouteMatch ||
          qmrDetailRouteMatch ||
          qmrEditRouteMatch ||
          manageDocumentationRouteMatch ||
          !!techlineCreateWorksheetRouteMatch ||
          !!isActualNewOpenCasesRouteMatch ||
          worksheetPreviewRouteMatch;
    const classes = useNavigationStyles({ open });
    const { abortSignalRef } = useAbortController();
    const [feedbackGroups, setFeedbackGroups] = useState<FeedbackGroup[]>([]);
    const [hoveredMap, setHoveredMap] = useState<{ [key: string]: boolean }>({});
    const [currentHover, setCurrentHover] = useState('');
    const [currentRef, setCurrentRef] = useState<HTMLElement>();
    const [openNavItems, setOpenNavItems] = useState({
        qmr: false,
        feedback: false,
        investigations: false,
        administration: false,
        techline: false,
    });

    const showQmrsDashboard = useMemo(() => {
        const eligiblePermissions: string[] = ['DASHBOARD001'];
        const hasPermissions = permissions?.filter(
            (permission: any) => eligiblePermissions.indexOf(permission.permissionId) > -1
        );
        return hasPermissions && hasPermissions?.length > 0;
    }, [permissions]);

    const showFailcodeTeamDashboard = useMemo(() => {
        const hasPermissions = permissions?.some(
            (permission: any) => permission.permissionId === 'ADM003' || permission.permissionId === 'ADM012'
        );
        return hasPermissions;
    }, [permissions]);

    const toggleNavItem = useCallback(
        (item: ExpandableNavItems, forceState?: boolean) => {
            setOpenNavItems({
                ...openNavItems,
                [item]: typeof forceState === 'boolean' ? forceState : !openNavItems[item],
            });
        },
        [openNavItems]
    );
    function handleNavigationTogglePress() {
        onOpenTogglePress(!open);
        setOpenNavItems({
            qmr: false,
            feedback: false,
            investigations: false,
            administration: false,
            techline: false,
        });
    }
    const fetchFeedbackSummary = useCallback(() => {
        return feedbackService
            .fetchFeedbackSummary({ signal: abortSignalRef.current })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                setFeedbackGroups(response.data.summary.feedbackGroups);
            })
            .catch((e) => {
                alert(e.message);
            });
    }, [abortSignalRef]);
    const fetchRecentSearches = useCallback(async () => {
        try {
            const response = await qmrsService.getAdvancedSearchRecentSavedSearches();
            if (response.success) {
                setRecentSavedSearches(response.data.savedSearches);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [setRecentSavedSearches]);
    const expandCollapsedNavigation = useCallback(
        (item: ExpandableNavItems) => {
            onOpenTogglePress(true);
            toggleNavItem(item, true);
        },
        [onOpenTogglePress, toggleNavItem]
    );
    useEffect(() => {
        if (hideNavigation) {
            return;
        }
        fetchFeedbackSummary();
        fetchQmrSummaries();
        fetchRecentSearches();
    }, [abortSignalRef, fetchFeedbackSummary, fetchQmrSummaries, fetchRecentSearches, hideNavigation]);
    const shouldFetchInvestigations = account?.investigationCapabilities?.viewInvestigations;
    useEffect(() => {
        if (shouldFetchInvestigations && !hideNavigation && openNavItems.investigations) {
            investigationService
                .getInvestigations({
                    queryString: 'size=5',
                    signal: abortSignalRef.current,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        alert(response.data.message);
                        return;
                    }
                    setRecentInvestigations(response.data);
                });
        }
    }, [abortSignalRef, shouldFetchInvestigations, hideNavigation, openNavItems.investigations]);

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    const handleOnHover = (title: string, ref?: any) => {
        hoveredMap[title] = true;
        setHoveredMap(hoveredMap);
        setCurrentHover(title);
        setCurrentRef(ref);
    };
    const handleOnHoverLeave = (title: string) => {
        hoveredMap[title] = false;
        setHoveredMap(hoveredMap);
        setCurrentHover('');
    };

    if (hideNavigation) {
        return null;
    }

    return (
        <>
            <div className={classes.navigationOuter}>
                <nav className={classes.navigation}>
                    <NavigationItem
                        iconName="home"
                        to="/"
                        title={t('nav:dashboard')}
                        isActive={!!landingRouteMatch}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                    />

                    <NavigationItem
                        iconName="car-subaru"
                        to={showQmrsDashboard ? '/qmrs/dashboard' : '/qmrs'}
                        title={t('nav:qmrs.title')}
                        isActive={
                            showQmrsDashboard
                                ? !!qmrsSlashDashboard
                                : !!qmrRouteMatch ||
                                  !!qmrWildcardRouteMatch ||
                                  !!advancedSearchRouteMatch ||
                                  !!escalationsRouteMatch ||
                                  !!escalationsWildcardRouteMatch ||
                                  !!investigationsRouteMatch ||
                                  !!investigationsWildcardRouteMatch ||
                                  !!qmrsSlashDashboard
                        }
                        isMainNavActive={!!qmrsSlashDashboard}
                        open={openNavItems.qmr}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                        onCollapseToggle={() => toggleNavItem('qmr')}
                        onClick={() => expandCollapsedNavigation('qmr')}
                    >
                        <NavigationSubItem
                            key="All QMRs"
                            badge=""
                            isActive={
                                (!!qmrRouteMatch || !!qmrWildcardRouteMatch) &&
                                !advancedSearchRouteMatch &&
                                !qmrsSlashDashboard
                            }
                            to="/qmrs"
                            title="All QMRs"
                        />
                        {account?.systemCapabilities.canViewEscalations && (
                            <NavigationSubItem
                                //iconName="escalation"
                                to="/escalations"
                                title={t('nav:escalations', 'Escalations')}
                                isActive={!!escalationsRouteMatch || !!escalationsWildcardRouteMatch}
                                //onHover={handleOnHover}
                                //onHoverLeave={handleOnHoverLeave}
                            />
                        )}
                        {account?.investigationCapabilities?.viewInvestigations && (
                            <NavigationSubItem
                                //iconName="folders"
                                to="/investigations"
                                title={t('nav:investigations')}
                                isActive={!!investigationsRouteMatch || !!investigationsWildcardRouteMatch}
                                //open={openNavItems.investigations}
                                //onHover={handleOnHover}
                                //onHoverLeave={handleOnHoverLeave}
                                // onCollapseToggle={() => toggleNavItem('investigations')}
                                // onClick={() => expandCollapsedNavigation('investigations')}
                            >
                                <div className="pl-4 pr-4">
                                    <hr className="mb-2" />
                                    <Typography variant="caption2" color="graySix">
                                        Recent
                                    </Typography>
                                </div>
                                {recentInvestigations.length > 0 &&
                                    recentInvestigations.map((investigation, index) => (
                                        <NavigationSubItem
                                            key={index}
                                            title={investigation.title}
                                            to={'/investigations/' + investigation.investigationId}
                                            isActive={
                                                investigationsDetailRouteMatch
                                                    ? investigation.investigationId ===
                                                      investigationsDetailRouteMatch.params.id
                                                    : false
                                            }
                                        />
                                    ))}
                            </NavigationSubItem>
                        )}
                        <NavigationSubItem
                            to="/qmrs/advanced-search/search"
                            title="Advanced Search"
                            //iconName="magnifying-glass"
                            isActive={!!advancedSearchRouteMatch}
                        />
                        <div className="pl-4 pr-4">
                            <button
                                className={classes.navigationSubItemCollapseButton}
                                onClick={() => {
                                    setSavedSearchesOpen(!savedSearchesOpen);
                                }}
                            >
                                <Icon
                                    name={savedSearchesOpen ? 'arrow-small-up' : 'arrow-small-down'}
                                    size={16}
                                    style={{ marginRight: 16 }}
                                />
                                <Typography>Saved Searches</Typography>
                            </button>
                        </div>
                        <Collapse in={savedSearchesOpen}>
                            <div>
                                {recentSavedSearches.map((search) => {
                                    return (
                                        <NavigationSubItem
                                            key={search.savedSearchId}
                                            to={`/qmrs/advanced-search/${search.savedSearchId}`}
                                            title={search.name}
                                            iconName="paragraph"
                                            isSubOfSub
                                            isActive={
                                                advancedSearchResultsRouteMatch
                                                    ? search.savedSearchId ===
                                                      advancedSearchResultsRouteMatch.params.searchId
                                                    : false
                                            }
                                        />
                                    );
                                })}
                                <NavigationSubItem
                                    to="/qmrs/advanced-search/saved"
                                    title="View All"
                                    iconName="eye"
                                    isSubOfSub
                                    isActive={!!savedSearchRouteMatch}
                                />
                            </div>
                        </Collapse>
                    </NavigationItem>
                    {account?.systemCapabilities?.canViewTechline && (
                        <NavigationItem
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                            iconName="phone"
                            to="/techline/dashboard"
                            title={t('nav:techline')}
                            isMainNavActive={!!techlineDashboardRouteMatch}
                            isActive={techlineRouteMatch}
                            open={openNavItems.techline}
                            onCollapseToggle={() => toggleNavItem('techline')}
                            onClick={() => expandCollapsedNavigation('techline')}
                        >
                            {/* <NavigationSubItem
                            to="/techline/dashboard"
                            title="Dashboard"
                            isActive={!!techlineDashboardRouteMatch}
                        /> */}
                            {/* <NavigationSubItem
                            to="/techline/all-cases"
                            title="Case list"
                            isActive={!!techlineAllCasesRouteMatch}
                            // badge={'70'}
                        /> */}
                            {/* <NavigationSubItem
                            to="/techline/draft"
                            title="Drafts"
                            isActive={!!techlineDraftsRouteMatch}
                            badge={'15'}
                        />
                        <NavigationSubItem
                            to="/techline/open-cases"
                            title="Open"
                            isActive={!!techlineOpenCasesRouteMatch}
                            badge={'25'}
                        />
                        <NavigationSubItem
                            to="/techline/closed-cases"
                            title="Closed"
                            isActive={!!techlineClosedCasesRouteMatch}
                            badge={'30'}
                        />
                        <NavigationSubItem
                            to="/techline/recent-searches"
                            title="Recent searches"
                            isActive={!!techlineRecentSearchesRouteMatch}
                        /> */}
                        </NavigationItem>
                    )}
                    {/* Commented out per SD-955 for UAT */}
                    {account?.systemCapabilities.feedbackNavigationEnabled && (
                        <NavigationItem
                            iconName="chat-bubble-text"
                            to="/feedback"
                            title={t('nav:feedback')}
                            isActive={!!feedbackRouteMatch || !!feedbackWildcardRouteMatch}
                            open={openNavItems.feedback}
                            onCollapseToggle={() => toggleNavItem('feedback')}
                            onClick={() => expandCollapsedNavigation('feedback')}
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                        >
                            {feedbackGroups.map((feedbackGroup, idx) => {
                                let path = `/feedback?groupType=${feedbackGroup.feedbackGroupType}`;
                                if (feedbackGroup.entityId) {
                                    path += `&entityId=${feedbackGroup.entityId}`;
                                }
                                return (
                                    <NavigationSubItem
                                        key={idx}
                                        to={path}
                                        title={feedbackGroup.listDescription}
                                        badge={feedbackGroup.count > 0 ? feedbackGroup.countDescription : ''}
                                        isActive={
                                            feedbackGroup.entityId
                                                ? !!feedbackRouteMatch &&
                                                  feedbackGroup.feedbackGroupType === queryGroupType &&
                                                  (queryEntityId ? feedbackGroup.entityId === queryEntityId : false)
                                                : !!feedbackRouteMatch &&
                                                  feedbackGroup.feedbackGroupType === queryGroupType
                                        }
                                    />
                                );
                            })}
                        </NavigationItem>
                    )}
                    {account?.systemCapabilities.adminstrationNavigationEnabled && (
                        <NavigationItem
                            iconName="administration"
                            to="/administration"
                            title={'Administration'}
                            isActive={!!adminRouteMatch || !!adminWildcardRouteMatch}
                            open={openNavItems.administration}
                            onCollapseToggle={() => toggleNavItem('administration')}
                            onClick={() => expandCollapsedNavigation('administration')}
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                        >
                            {account?.systemCapabilities.canViewFailCodeApprover && (
                                <NavigationSubItem
                                    title="Manage Retailers"
                                    to="/administration/retailers"
                                    isActive={!!manageRetailersRouteMatch}
                                />
                            )}

                            {account?.systemCapabilities.canEditUserPermissions && (
                                <NavigationSubItem
                                    title="Manage Users"
                                    to="/administration/users"
                                    isActive={!!manageUsersRouteMatch}
                                />
                            )}
                            {account?.systemCapabilities.manageFeedbackEntities && (
                                <NavigationSubItem
                                    title="Manage Entities"
                                    to="/administration/entities"
                                    isActive={!!manageEntitiesRouteMatch}
                                />
                            )}
                            {account?.systemCapabilities.manageFeedbackForms && (
                                <NavigationSubItem
                                    title="Manage Feedback"
                                    to="/administration/feedback"
                                    isActive={!!manageFeedbackRouteMatch}
                                />
                            )}
                            {account?.techlineCapabilities.canCreateWorkSheetsTechlineCase && (
                                <NavigationSubItem
                                    title="Manage Worksheets"
                                    to="/administration/worksheets"
                                    isActive={!!manageWorkSheetsRouteMatch}
                                />
                            )}
                            {account?.systemCapabilities.viewToughbookAttachments && (
                                <NavigationSubItem
                                    title="Toughbook Attachments"
                                    to="/administration/toughbook-attachments"
                                    isActive={!!toughbookAttachmentsRouteMatch}
                                />
                            )}
                            {permissions?.filter((p: any) => p.permissionId === 'DASHBOARD002')?.length > 0 && (
                                <NavigationSubItem
                                    title={t('nav:contentSettings', 'Content Settings')}
                                    to="/administration/content-settings"
                                    isActive={
                                        !!manageContentSettings ||
                                        !!qmrsDashboardContentSettings ||
                                        !!techlineDashboardContentSettings
                                    }
                                />
                            )}
                            {showFailcodeTeamDashboard && (
                                <NavigationSubItem
                                    title={t('nav:failCodeTeams', 'Fail Code Teams')}
                                    to="/administration/fail-code-teams"
                                    isActive={!!failCodeTeamRouteMatch}
                                />
                            )}
                        </NavigationItem>
                    )}
                </nav>
                <div className={classes.collapseButtonOuter}>
                    <Button variant="link" onPress={handleNavigationTogglePress}>
                        <div className={classes.collapseButton}>
                            <Icon name={open ? 'chevron-left' : 'chevron-right'} color="blueOne" size={16} />
                        </div>
                    </Button>
                </div>
            </div>
            {currentHover && !open && <NavigationItemHoverTitle forwardRef={currentRef} title={currentHover} />}
        </>
    );
};
export default Navigation;
