import React from 'react';
import { Icon, Typography } from '@packages/ui/shared';

export const Comments = ({ comments, currentUserName }: { comments?: any[]; currentUserName?: string }) => {
    const renderComment = (comment: any, index: number) => {
        return (
            <div key={index} className="d-flex flex-row mt-5">
                <Icon size={24} name="user" />
                <div className="d-flex flex-column ml-3 mr-3">
                    <div className="d-flex flex-row align-items-center mb-1">
                        <Typography variant="h6" color="blueOne">
                            {comment.jobTitle ? comment.jobTitle : ''}
                        </Typography>
                        <Typography variant="h6" color="blueOne">
                            {/* {currentUserName === comment.userName ? comment.fullName : 'Techline Technician'} */}
                            {comment.fullName ? comment.fullName : ''}
                        </Typography>
                        <Typography color="textDarkSecondary" variant="caption">
                            {' '}
                            {comment.createdOn}
                        </Typography>
                    </div>
                    <Typography>{comment.message}</Typography>
                </div>
            </div>
        );
    };

    return <div className="ml-2 mr-3">{comments?.map((comment: any, index) => renderComment(comment, index))}</div>;
};
