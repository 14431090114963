import React, { FC, useState, useEffect, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '@packages/ui/shared';
import { analyticsService, feedbackService, httpClient, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import {
    FeedbackFormAnswer,
    FeedbackFormElement as QmrFeedbackFormElement,
    FeedbackFormTypeId,
} from '@packages/models/api';
import { FeedbackFormElement } from '@web/feedback/components';
import { fetchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

interface QmrFeedbackModalProps {
    show: boolean;
    onHide(): void;
}

const QmrFeedbackModal: FC<QmrFeedbackModalProps> = (props) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackFormId, setFeedbackFormId] = useState('');
    const [leftColElements, setLeftColElements] = useState<QmrFeedbackFormElement[]>([]);
    const [rightColElements, setRightColElements] = useState<QmrFeedbackFormElement[]>([]);

    const [sessionChoices, setSessionChoices] = useState<Record<string, FeedbackFormAnswer>>({});

    const hasAnsweredChoices = useMemo(() => {
        return Object.values(sessionChoices).length > 0;
    }, [sessionChoices]);

    useEffect(() => {
        if (!props.show) {
            return;
        }

        feedbackService.fetchQmrFeedbackForm({ signal: abortSignalRef.current }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            const { feedbackForm } = response.data;
            setFeedbackFormId(feedbackForm.feedbackFormId);
            setLeftColElements(feedbackForm.formElements.slice(0, 4));
            setRightColElements(feedbackForm.formElements.slice(4));
        });
    }, [abortSignalRef, props.show]);

    const renderFormEl = (el: QmrFeedbackFormElement) => (
        <FeedbackFormElement
            key={el.formElementId}
            item={el}
            onAnswerChange={(newAnswer: FeedbackFormAnswer) => {
                httpClient.refreshToken();
                setSessionChoices({
                    ...sessionChoices,
                    [el.formElementId]: newAnswer,
                });
            }}
        />
    );

    return (
        <Modal show={props.show} centered={true} onHide={props.onHide} size="lg">
            <Modal.Header className="d-flex justify-content-between align-items-center" placeholder={''}>
                <Modal.Title>{t('modals:qmrFeedback.title', 'QMR Feedback')}</Modal.Title>

                <div className="d-inline-flex">
                    <Button onPress={props.onHide} variant="ghost-blue">
                        {t('modals:createQmr.actions.skip', 'SKIP')}
                    </Button>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>{leftColElements.map(renderFormEl)}</Col>
                    <Col>{rightColElements.map(renderFormEl)}</Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    isLoading={isSubmitting}
                    variant="primary"
                    disabled={!hasAnsweredChoices}
                    onPress={() => {
                        if (!qmr || !feedbackFormId) {
                            return;
                        }

                        const choices = Object.values(sessionChoices);

                        setIsSubmitting(true);

                        feedbackService
                            .submitQmrFeedback({
                                qmrId: qmr.qmrId,
                                feedbackFormId,
                                answers: { choices },
                                signal: abortSignalRef.current,
                            })
                            .then((response) => {
                                if (!response.success && response.aborted) {
                                    return;
                                } else if (!response.success) {
                                    throw response.data;
                                }

                                analyticsService.logEvent(ANALYTICS_EVENTS.USER_GIVES_FEEDBACK, {
                                    feedbackType: FeedbackFormTypeId.Qmr,
                                    tsId: qmr.stsIdentifier,
                                });

                                setIsSubmitting(false);
                                fetchQmr({
                                    qmrId: qmr.qmrId,
                                    qmrsService,
                                    qmrDispatch,
                                    signal: abortSignalRef.current,
                                    ignoreCache: true,
                                });
                                props.onHide();
                            })
                            .catch((e) => {
                                setIsSubmitting(false);
                                alert(e.message);
                            });
                    }}
                >
                    {t('modals:createQmr.actions.submit', 'Submit Feedback')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QmrFeedbackModal;
