import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Icon, TextBadge, Typography } from '@packages/ui/shared';
import { TableCell, TableCheckbox, TableRow } from '@web/components/table';

import { Qmr } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';
import { getQmrRoleReview } from '@packages/contexts/qmrs';
import { analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

interface QmrTableRowProps {
    qmr: Qmr;
    checked: boolean;
    onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void;
    listQueryParams?: string;
}

export const QmrTableRow: FC<QmrTableRowProps> = React.memo(({ qmr, checked, onCheckboxChange, listQueryParams }) => {
    const { account } = useAuthState();
    const roleReview = useMemo(() => getQmrRoleReview(qmr), [qmr]);
    const search = useLocation().search;

    const trackAnalytics = (qmrId: string) => {
        analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_TO_REVIEW_QMR, {
            QMR_ID: qmrId,
        });
    };

    return (
        <TableRow checked={checked}>
            {(account?.systemCapabilities.canExportQMRsAsCsv ||
                account?.investigationCapabilities.editAnInvestigation) && (
                <TableCell fixed classNameOuter="align-middle">
                    <TableCheckbox value={qmr.qmrId} checked={checked} onChange={onCheckboxChange} />
                </TableCell>
            )}
            {account?.systemCapabilities.viewQmrRestrictedOnList && (
                <TableCell classNameOuter="align-middle">
                    {qmr.restricted && <Icon name="lock-closed" size={24} />}
                </TableCell>
            )}
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}>{qmr.lastEditedTimestampDescription}</Typography>
            </TableCell>
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}>
                    <a
                        href={`/qmrs/${qmr.displayIdentifier}${search}`}
                        onClick={() => trackAnalytics(qmr.displayIdentifier)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {qmr.displayIdentifier}
                    </a>
                </Typography>
                <div className="d-flex align-items-center">
                    <div className="mr-1" style={{ transform: 'rotate(90deg)' }}>
                        <Icon name="paperclip" size={14} />
                    </div>
                    <Typography variant="caption" color="textDarkSecondary">
                        {qmr.assets?.length} attachments
                    </Typography>
                </div>
            </TableCell>
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}> {qmr.vin?.slice(-8)}</Typography>
            </TableCell>
            <TableCell classNameOuter="align-middle" width={215}>
                <div className="d-inline-flex">
                    <TextBadge
                        icon={<Icon size={16} name={roleReview.qa.iconName} color={roleReview.qa.iconColor} />}
                        variant={roleReview.qa.badgeVariant}
                    >
                        {qmr.qmrStatus.description}
                    </TextBadge>
                </div>
            </TableCell>
            {account?.systemCapabilities.viewQmrRoleReviewOnList && (
                <TableCell classNameOuter="align-middle">
                    <div className="d-flex align-items-center">
                        <Icon size={16} name={roleReview.dqsm.iconName} color={roleReview.dqsm.iconColor} />
                        <span className="ml-1">
                            <Typography variant="caption">DSQM</Typography>
                        </span>
                    </div>
                    <div className="d-flex align-items-center">
                        <Icon size={16} name={roleReview.qa.iconName} color={roleReview.qa.iconColor} />
                        <span className="ml-1">
                            <Typography variant="caption">QA</Typography>
                        </span>
                    </div>
                </TableCell>
            )}
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}>{qmr.modelYear}</Typography>
            </TableCell>
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}>{qmr.carlineName}</Typography>
            </TableCell>
            <TableCell classNameOuter="align-middle">
                {qmr.failCodeApproved === false && (
                    <Typography numberOfLines={1} color="graySix">
                        Pending
                    </Typography>
                )}

                {qmr.failCodeApproved !== false && (
                    <>
                        <div className={qmr.failCodeSectionName ? 'mb-1' : 'mb-0'}>
                            <Typography numberOfLines={1} color="blueOne">
                                {qmr.failCode}
                            </Typography>
                        </div>
                        {qmr.failCodeSectionName && (
                            <div className={qmr.failCodeSubsectionName ? 'mb-1' : 'mb-0'}>
                                <Typography numberOfLines={1} variant="caption" color="graySix">
                                    {qmr.failCodeSectionName}
                                </Typography>
                            </div>
                        )}
                        {qmr.failCodeSubsectionName && (
                            <div>
                                <Typography numberOfLines={1} variant="caption" color="graySix">
                                    {qmr.failCodeSubsectionName}
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </TableCell>
            <TableCell classNameOuter="align-middle">
                <Typography numberOfLines={1}>{qmr.retailer?.name}</Typography>
                <div>
                    <Typography variant="caption" color="textDarkSecondary">
                        &#35;{qmr.retailer?.code}
                    </Typography>
                </div>
            </TableCell>
        </TableRow>
    );
});
