import { WorksheetElementAnswers, WorksheetForm, WorksheetFormElement } from '@packages/models/api/worksheets';
import { worksheetsService } from '@web/services/singletons';
import { useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';
import { findLatest } from '../../../../administration/views/worksheets/utils/worksheet-form';
const useWorksheetSubmissionData = ({
    caseNumber,
    submissionId,
    worksheetId,
}: {
    caseNumber: string;
    submissionId: string;
    worksheetId: string;
}) => {
    const [worksheetSubmissionData, setWorksheetSubmissionData] = useState<WorksheetForm>();
    const [fetching, setFetching] = useState<boolean>(false);

    const fetchWorksheetData = useCallback(async () => {
        setFetching(true);
        worksheetsService
            .fetchWorksheetFormById(worksheetId)
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    console.log(response.data);
                } else {
                    const data = response.data;
                    setWorksheetSubmissionData(data);
                }
                setFetching(false);
            })
            .catch((error) => {
                alert('Error: ' + error + '. Please refresh & try again.');
                console.log(error);
                setFetching(false);
            });
    }, [worksheetId]);

    const fetchRetrieveWorksheetSubmission = useCallback(async () => {
        setFetching(true);
        worksheetsService
            .retrieveWorksheetSubmission({ submissionId, caseNumber })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    console.log(response.data);
                } else {
                    const data = response.data;
                    setWorksheetSubmissionData(data);
                }

                setFetching(false);
            })
            .catch((error) => {
                alert('Error: ' + error + '. Please refresh & try again.');
                console.log(error);
                setFetching(false);
            });
    }, [caseNumber, submissionId]);

    const prepareElementAnswer = useCallback((elements: WorksheetFormElement[]) => {
        let elementsSelectedOptions: { [x: string]: WorksheetElementAnswers } = {};

        const elementsClone = cloneDeep(elements);
        elementsClone?.forEach((element) => {
            if (element.selectedOptions?.length || element.answerAssets?.length) {
                elementsSelectedOptions = {
                    ...elementsSelectedOptions,
                    [element.worksheetElementId]: {
                        elementId: element.worksheetElementId,
                        selectedOptions:
                            element.selectedOptions?.map((option) => ({
                                optionId: option.optionId,
                                optionLabel: option.optionLabel || '',
                                answerText: option.answerText || '',
                            })) || [],
                        asset: { answerFile: findLatest(element.answerAssets)! },
                    },
                };
            }
        });
        return elementsSelectedOptions;
    }, []);

    return {
        worksheetData: worksheetSubmissionData,
        fetching,
        actions: {
            fetchWorksheetData,
            fetchRetrieveWorksheetSubmission,
            prepareElementAnswer,
        },
    };
};

export default useWorksheetSubmissionData;
