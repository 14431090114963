import React, { FC } from 'react';

import { FeedbackFormAnswer, FeedbackFormElement, FeedbackFormElementTypeId } from '@packages/models/api';

import { FeedbackFormElementComponent, FeedbackFormElements } from './feedback-form-elements';

export interface FeedbackFormElementRenderProps {
    item: FeedbackFormElement;
    readOnly?: boolean;
    onAnswerChange?: (answer: FeedbackFormAnswer) => void;
}

interface FeedbackFormElementProps extends FeedbackFormElementRenderProps {
    dropdownRenderer: (props: FeedbackFormElementRenderProps) => JSX.Element;
}

export const BaseFeedbackFormElement: FC<FeedbackFormElementProps> = ({
    item,
    readOnly,
    onAnswerChange,
    dropdownRenderer,
}) => {
    if (item.formElementTypeId === FeedbackFormElementTypeId.Dropdown) {
        return dropdownRenderer({ item, readOnly, onAnswerChange });
    }

    const FormElementComponent: FeedbackFormElementComponent = FeedbackFormElements?.[item.formElementTypeId];

    if (!FormElementComponent) {
        return null;
    }

    return <FormElementComponent readOnly={readOnly} onAnswerChange={onAnswerChange} {...item} />;
};
