import { HttpClient } from '@packages/core/http';
import {
    CreateEscalationResponseDto,
    EscalationsQuickFiltersResponseDto,
    GetEscalationByIdRepsonseDto,
    GetEscalationsResponseDto,
    TrReplyModel,
} from '@packages/models/api';

export class EscalationService {
    constructor(private http: HttpClient) {}

    fetchEscalations({
        queryString,
        ignoreCache = false,
        signal,
    }: {
        queryString: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = `/escalations?${queryString}`;

        return this.http.orchestrateRequest<GetEscalationsResponseDto>({
            method: 'GET',
            url: url,
            ignoreCache,
            signal,
        });
    }

    fetchEscalationById(trReplyId: string, ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<GetEscalationByIdRepsonseDto>({
            method: 'GET',
            url: `/escalation/tr-reply/${trReplyId}`,
            ignoreCache,
            signal,
        });
    }

    fetchEscalationsQuickFilters(ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<EscalationsQuickFiltersResponseDto>({
            method: 'GET',
            url: '/escalations/quick-filters',
            ignoreCache,
            signal,
        });
    }

    createEscalation({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: {
            qmrIds: string[];
            subject: string;
            summary: string;
            importance: string;
            priority: string;
            trouble: string;
            distRefNo: string;
            isFfdAvailable: string;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<CreateEscalationResponseDto>({
            method: 'POST',
            url: '/escalation',
            body,
            ignoreCache,
            signal,
        });
    }

    trReply({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: {
            trReplyId: string;
            shareBySqip?: boolean;
            shareByInvestigation?: boolean;
            retailerTechnicianCorporateMessage?: string;
            soaFseNasiMessage?: string;
            sneSdcFseMessage?: string;
            closeTrReply?: boolean;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<TrReplyModel>({
            method: 'PATCH',
            url: '/escalation/tr-reply-message',
            body,
            ignoreCache,
            signal,
        });
    }
}
