import { BadgeVariants } from '@packages/ui/shared';

export const getBadgeVariant = (status: string): BadgeVariants => {
    switch (status) {
        // https://subaruofamerica.atlassian.net/browse/TECHSHARE-1769
        case 'Pending':
            return 'yellow';
        case 'NOT_ESCALATED':
            return 'gray';
        case 'ESCALATION_FAILED':
            return 'red';
        case 'TRANSFERRING':
            return 'yellow';
        case 'SENT_TO_TR':
            return 'gray';
        case 'TR_DRAFT':
            return 'gray';
        case 'TR_ISSUED':
            return 'yellow';
        case 'TEMPORARY_REPLY_RECEIVED':
            return 'yellow';
        case 'FINAL_REPLY_RECEIVED':
            return 'green';
        case 'FINAL_REPLY_APPROVED':
            return 'green';
        case 'FINAL_REPLY_REFUSED':
            return 'red';
        default:
            return 'yellow';
    }
};

export const getBadgeVariantTRSharedReply = (status: string): BadgeVariants => {
    switch (status) {
        case 'SHARED':
            return 'green';
        case 'PENDING':
            return 'gray';
        case 'CLOSED':
            return 'green';
        case 'NO_REPLY':
            return 'yellow';
        default:
            return 'yellow';
    }
};

export const caseStatusMap: any = {
    DRAFT: ['DRAFT'],
    OPEN: ['OPEN', 'OPEN-ESCALATED'],
    CLOSED: [
        'CLOSED',
        'CLOSED-RESOLVED',
        'CLOSED-RESOLUTION UNKNOWN',
        'CLOSED-APPROVED',
        'CLOSED-REFERRED',
        'CLOSED-REJECTED',
        'CLOSED-CREATED IN ERROR',
        'CLOSED-DUPLICATE',
    ],
    PENDING_TECHLINE: [
        'PENDING-TECHLINE',
        'PENDING_TECHLINE',
        'PENDING-RESEARCH',
        'PENDING-C/M',
        'PENDING-PART DELAY',
        'DEALER UPDATE',
        'PENDING TECHLINE',
    ],
    UPDATE_REQUESTED: ['UPDATE_REQUESTED', 'PENDING-DEALER UPDATE'],
};

export const getBadgeVariantTechline = (status: string): BadgeVariants => {
    for (const variant in caseStatusMap) {
        if (caseStatusMap[variant].includes(status)) {
            switch (variant) {
                // Add edge case to remove the underscore to stay consistent in the variant styling file
                case 'PENDING_TECHLINE':
                    return 'PENDING';
                case 'UPDATE_REQUESTED':
                    return 'UPDATE';
                default:
                    return variant as BadgeVariants;
            }
        }
    }

    return 'default';
};
