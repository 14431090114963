import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { Icon, TextBadge, Typography } from '@packages/ui/shared';

import { analyticsService, accountsService, qmrsService } from '@web/services/singletons';
import { SavedSearch } from '@packages/models/api';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import AdvancedSearchDeleteModal from '@web/qmr/components/advanced-search-delete-modal';
import AdvancedSearchModal from '@web/qmr/components/advanced-search-modal';
import { useAdvancedSearchContext } from '@packages/contexts/qmrs';
import { useAuthState } from '@packages/contexts/auth';
import useQuery from '@web/core/hooks/use-query';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

type SavedSearchTableRowModel = {
    rowId: string;
    checked: boolean;
    sharedUserRoles?: string[];
} & SavedSearch;

const useStyles = createUseStyles({
    tabelCell: {
        display: 'flex',
        overflow: 'visible',
        padding: '0 8px 0 0',
        justifyContent: 'flex-end',
    },
    tableCellOuter: {
        verticalAlign: 'middle',
    },
    dropdownToggle: {
        '&:after': {
            display: 'none',
        },
    },
    dropdownMenu: {
        width: 243,
        borderRadius: 3,
        padding: '8px 0 !important',
        '& .dropdown-item': {
            display: 'flex',
            padding: '10px 16px !important',
        },
    },
});

const AdvancedSearchSaved: FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const query = useQuery();
    const { account } = useAuthState();
    const { recentSavedSearches, setRecentSavedSearches } = useAdvancedSearchContext();

    const pageFromQuery = query.get('page');
    const sizeFromQuery = query.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = query.get('sortOrder');
    const parsedSortDirection = query.get('sortDir');

    const [isDeleting, setIsDeleting] = useState(false);
    const [searchToDelete, setSearchToDelete] = useState<SavedSearchTableRowModel>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [searchToEdit, setSearchToEdit] = useState<SavedSearchTableRowModel>();
    const [showEditModal, setShowEditModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [savedSearches, setSavedSearches] = useState<SavedSearchTableRowModel[]>([]);
    const [total, setTotal] = useState(0);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await qmrsService.getAdvancedSearchSavedSearches({
                page: parsedPage,
                size: parsedSize,
                ...(parsedSortOrder ? { sortOrder: parsedSortOrder } : {}),
                ...(parsedSortDirection ? { sortDir: parsedSortDirection } : {}),
            });

            if (response.success) {
                const savedSearchTableRows = response.data.savedSearches.map((savedSearch) => {
                    return Object.assign(
                        {
                            rowId: savedSearch.savedSearchId,
                            checked: false,
                        },
                        savedSearch
                    );
                });

                setSavedSearches(savedSearchTableRows);
                setTotal(response.data.totalCount);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }

        setIsLoading(false);
    }, [parsedPage, parsedSize, parsedSortDirection, parsedSortOrder, recentSavedSearches]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handleDropdownSelect = useCallback(
        async (eventKey: any, savedSearch: any) => {
            switch (eventKey) {
                case 'MARK_DEFAULT':
                    try {
                        if (!account) {
                            throw new Error('Could not find account.');
                        }

                        const accountResponse = await accountsService.updateAccount(account.accountId, {
                            defaultSavedSearchId: savedSearch.savedSearchId,
                        });

                        if (accountResponse.success) {
                            fetchData();
                        } else {
                            throw accountResponse.data;
                        }
                    } catch (error) {
                        window.alert(error.message);
                    }
                    break;
                case 'DELETE':
                    setSearchToDelete(savedSearch);
                    setShowDeleteModal(true);
                    break;
                case 'EDIT':
                    setSearchToEdit(savedSearch);
                    setShowEditModal(true);
                    break;
                default:
                    return;
            }
        },
        [account, fetchData]
    );

    const handleConfirmDelete = useCallback(async () => {
        try {
            if (!searchToDelete) {
                throw new Error('Could not find searchId.');
            }

            setIsDeleting(true);

            const deleteResponse = await qmrsService.deleteAdvancedSearchSavedSearch(searchToDelete.savedSearchId);

            if (deleteResponse.success) {
                setShowDeleteModal(false);
                setSearchToDelete(undefined);
                setIsDeleting(false);

                await fetchData();
                const recentSearchesResponse = await qmrsService.getAdvancedSearchRecentSavedSearches();

                if (recentSearchesResponse.success) {
                    setRecentSavedSearches(recentSearchesResponse.data.savedSearches);
                } else {
                    throw recentSearchesResponse.data;
                }
            } else {
                throw deleteResponse.data;
            }
        } catch (error) {
            setIsDeleting(false);
            window.alert(error.message);
        }
    }, [fetchData, searchToDelete, setRecentSavedSearches]);

    async function handleSave(name: string, publicSearch: boolean, rolesToShareSelected: string[]) {
        try {
            const dataToPatch = {
                ...searchToEdit,
                name,
                publicSearch,
                sharedUserRoles:
                    rolesToShareSelected && rolesToShareSelected.length > 0 ? [...rolesToShareSelected] : [],
            };
            if (dataToPatch.savedSearchId) {
                const response = await qmrsService.updateAdvancedSearchQuery(
                    dataToPatch.savedSearchId,
                    dataToPatch.query,
                    dataToPatch.name,
                    dataToPatch.publicSearch,
                    dataToPatch.sharedUserRoles
                );
                const recentSearchsResponse = await qmrsService.getAdvancedSearchRecentSavedSearches();

                if (recentSearchsResponse.success) {
                    setRecentSavedSearches(recentSearchsResponse.data.savedSearches);
                }

                if (response.success) {
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_SAVES_ADVANCED_SEARCH);
                } else {
                    throw response.data;
                }
            }
            setShowEditModal(false);
        } catch (error) {
            setShowEditModal(false);
            window.alert(error.message);
        }
    }

    const getSharedDisplayData = (sharedRolesArray: string[]) => {
        const returnString = sharedRolesArray
            .join(', ')
            .replace('FSE_USER', 'DSQM (FSE_USER)')
            .replace('FSE_MANAGER', 'RSQM (FSE_MANAGER)')
            .replace('SOA_SUPER_USER', 'SOA Super User')
            .replace('NASI_SIA_ADMIN', 'NASI + SIA Admin')
            .replace('QA_ADMIN', 'QA Admin');

        return `Shared to: ${returnString}`;
    };
    return (
        <>
            <AdvancedSearchDeleteModal
                isLoading={isDeleting}
                searchToDelete={searchToDelete}
                show={showDeleteModal}
                onConfirm={handleConfirmDelete}
                onHide={() => {
                    setShowDeleteModal(false);
                }}
            />

            <AdvancedSearchModal
                show={showEditModal}
                defaultSavedSearch={searchToEdit}
                onSave={handleSave}
                onHide={() => {
                    setShowEditModal(false);
                }}
            />

            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <Typography variant="h2">All Saved Searches</Typography>
                    </div>
                    <TableRenderer<SavedSearchTableRowModel>
                        isLoading={isLoading}
                        tableRowsData={savedSearches}
                        tableRowsDataSetter={setSavedSearches}
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader>
                                        <Typography variant="label">Name</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        onSort={(_event, _sortDirection) => {
                                            return;
                                        }}
                                    >
                                        <Typography variant="label">Creation Date</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">Author</Typography>
                                    </TableHeader>
                                    <TableHeader hideBorder>
                                        <Typography variant="label">Visibility</Typography>
                                    </TableHeader>
                                    <TableHeader />
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            console.log('rowdata-----------------------------', rowData);
                            return (
                                <TableRow key={rowData.rowId}>
                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                <Link to={`/qmrs/advanced-search/${rowData.savedSearchId}`}>
                                                    {rowData.name}
                                                </Link>
                                            </Typography>
                                            {rowData.isAccountDefault && (
                                                <TextBadge variant="blue" style={{ marginLeft: 8 }}>
                                                    Default
                                                </TextBadge>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{rowData.createdTimestampDescription}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{rowData.account.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {rowData.publicSearch
                                                ? 'Public'
                                                : rowData?.sharedUserRoles && rowData.sharedUserRoles.length > 0
                                                  ? getSharedDisplayData(rowData.sharedUserRoles)
                                                  : 'Private'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tabelCell} classNameOuter={classes.tableCellOuter}>
                                        {rowData.actions.length > 0 && (
                                            <Dropdown
                                                onSelect={(eventKey) => {
                                                    handleDropdownSelect(eventKey, rowData);
                                                }}
                                            >
                                                <Dropdown.Toggle className={classes.dropdownToggle} variant="link">
                                                    <Icon name="more-dots-vertical" style={{ display: 'flex' }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={classes.dropdownMenu}>
                                                    {rowData.actions.map((action) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={action.savedSearchActionId}
                                                                eventKey={action.savedSearchActionId}
                                                            >
                                                                <Typography variant="labelRegular">
                                                                    {action.description}
                                                                </Typography>
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                    <TablePagination
                        page={parsedPage}
                        size={parsedSize}
                        total={total}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                    />
                </div>
            </div>
        </>
    );
};

export default AdvancedSearchSaved;
