import React, { FC } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { createIconSetFromIcoMoon } from 'react-native-vector-icons';
import { IconProps as RNVIIconProps } from 'react-native-vector-icons/Icon';

import colors from '@packages/core/styles/colors';

import icoMoonConfig from '../assets/sts-icons.json';

const STSIcon = createIconSetFromIcoMoon(icoMoonConfig, 'STS-Icons', 'STS-Icons.ttf');

interface IconProps extends RNVIIconProps {
    name: StsIconName;
    hero?: boolean;
    heroContainerStyle?: StyleProp<ViewStyle>;
    raised?: boolean;
    raisedContainerStyle?: StyleProp<ViewStyle>;
    color?: keyof typeof colors;
}

export const Icon: FC<IconProps> = ({
    hero,
    heroContainerStyle,
    raised,
    raisedContainerStyle,
    color,
    ...iconProps
}) => {
    const colorHex = colors[color || (hero ? 'graySix' : color || 'textDarkSecondary')];

    const icon = <STSIcon color={colorHex} size={hero ? 120 : 24} {...iconProps} />;

    if (hero) {
        return (
            <View
                style={[
                    styles.heroIconContainer,
                    heroContainerStyle,
                    color === 'white' && { backgroundColor: colors.blueOne },
                ]}
            >
                {icon}
            </View>
        );
    }

    if (raised) {
        return <View style={[styles.raisedIconContainer, raisedContainerStyle]}>{icon}</View>;
    }

    return icon;
};

const styles = StyleSheet.create({
    raisedIconContainer: {
        backgroundColor: colors.white,
        borderRadius: 50,
        height: 32,
        width: 32,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
    heroIconContainer: {
        padding: 36,
        borderRadius: 100,
        backgroundColor: colors.grayTwo,
    },
});

export const StsIconNamesAsArray = [
    'administration',
    'arrow-back',
    'arrow-double-up',
    'arrow-forward',
    'arrow-small-down',
    'arrow-small-up',
    'barcode-scan',
    'bell',
    'bell-outline',
    'calendar',
    'camera',
    'car-subaru',
    'chat-bubble',
    'chat-bubble-text',
    'check-circle-hollow',
    'checkbox-group',
    'checkbox-indeterminate',
    'checkbox-marked',
    'checkbox-unmarked',
    'checkmark',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'circle-indeterminate',
    'download',
    'electrical',
    'entity',
    'error',
    'eye',
    'eye-off',
    'file',
    'filter-list',
    'flag',
    'folders',
    'fork',
    'gear',
    'gear-outline',
    'headset',
    'history',
    'home',
    'image',
    'image-collection',
    'info',
    'keyboard-return',
    'likert',
    'likert-long-text',
    'list',
    'lock-closed',
    'lock-open',
    'long-text',
    'magnifying-glass',
    'magnify-minus-outline',
    'magnify-plus-outline',
    'mark-as-read',
    'mark-as-unread',
    'menu',
    'microphone-outline',
    'minus',
    'more-dots',
    'more-dots-vertical',
    'open-in-browser',
    'paintbrush',
    'paintbrush-outline',
    'paperclip',
    'paragraph',
    'pencil',
    'phone',
    'plus',
    'question-mark',
    'radio-group',
    'radio-marked',
    'radio-unmarked',
    'refresh',
    'select',
    'share',
    'short-text',
    'title',
    'trash',
    'upload',
    'upload-in-progress',
    'user',
    'videocam',
    'warning',
    'wifi-off',
    'x-circle',
    'x-close',
    'logout',
    'escalation',
    'launch',
    'copy',
    'draggable',
    'clock',
    'update',
    'system_update',
] as const;

export type StsIconName = (typeof StsIconNamesAsArray)[number];

export const isValidIconType = (type: StsIconName) => {
    return StsIconNamesAsArray.includes(type);
};
