import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Card from './card';
import WrapperAndContent from './wrapper';
import { AccountsService } from '@packages/contexts/accounts';
import { Account } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';

interface Props {
    content: string;
    accountService: AccountsService;
    accountId?: string;
    userName?: string;
    contentZIndex?: number | string;
}

const InfoCard: FC<Props> = (props) => {
    const [isValid, setIsValid] = useState(false);
    const [userInfo, setUserInfo] = useState<Account>();
    const { account } = useAuthState();
    const { userName, accountId, accountService } = props;

    const hasPermission = useMemo(() => {
        const roleHavePermission = [
            'LAG_SUPER_ADMIN',
            'QA_ADMIN',
            'SOA_SUPER_USER',
            'FSE_USER',
            'DSQM',
            'FSE_MANAGER',
            'RSQM',
        ];
        return account?.baseRoleIds.some((roleId) => roleHavePermission.includes(roleId));
    }, [account]);

    useEffect(() => {
        if (hasPermission && (Boolean(userName) || Boolean(accountId))) {
            fetchInfo();
        }
    }, [accountService, userName, accountId, hasPermission]);

    const fetchInfo = useCallback(() => {
        try {
            accountService.getUserInfoCardDetails({ accountId, userName }).then((res) => {
                if (res.success) {
                    setIsValid(true);
                    setUserInfo(res.data.account);
                }
            });
        } catch (error) {
            console.log('error:', error);
        }
    }, [accountId, accountService, userName]);

    return (
        <>
            <WrapperAndContent content={props.content} isValid={isValid} contentZIndex={props.contentZIndex}>
                <Card userInfo={userInfo} />
            </WrapperAndContent>
        </>
    );
};

export default InfoCard;
